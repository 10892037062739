const phoneFormatter = (phone) => {
  if (!phone) return "-"
  const phoneWithoutDDI = phone.replace(/^(?:\+\d{1,2}|\d{1,2})\s*/, "")
  if (phoneWithoutDDI.length === 10)
    return phoneWithoutDDI.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
  if (phoneWithoutDDI.length === 11)
    return phoneWithoutDDI.replace(
      /(\d{2})(\d{1})(\d{4})(\d{4})/,
      "($1) $2 $3-$4"
    )
}

export { phoneFormatter }

<style lang="scss">
  @import "./service-details.scss";
</style>

<script>
  import { formatCurrency } from "@utils/currency"

  export let franchiseeName = "";
  export let isMyOwnFranchise = false;
  export let value = 0;
</script>

<div class="service-details">
  <div>
    <div class="{`service-details-icon ${isMyOwnFranchise === true ? 'icon-blue' : ''}`}">
      {isMyOwnFranchise ? "Minha escola" : "Escola"}
    </div>
  </div>
  <div class="service-details-info">
    <div class="franchisee-name">{franchiseeName}</div>
    <div class="service-details-row">
      <div class="service-details-label">Valor</div>
      <div class="service-details-value">{formatCurrency(value)}</div>
    </div>
  </div>
</div>

<style lang="scss">
  @import "./header.scss";
</style>

<script>
  import defaultLogo from "@assets/images/logo-cna.svg"
  export let logo = defaultLogo;
</script>

<header class="header">
  <div class="inner-header">
    <img src="{logo}" alt="CNA - Checkout" />
  </div>
</header>

<style lang="scss">
  @import "buttons.scss";
  @import "course.scss";
  @import "purchase-details.scss";
</style>

<script>
  import { getUrlParams } from "@utils/url"
  import AcademicCap from "@assets/images/icons/outline/academic-cap.svg"
  import Clock from "@assets/images/icons/outline/clock.svg"
  import Calendar from "@assets/images/icons/outline/calendar.svg"
  import BookOpen from "@assets/images/icons/outline/book-open.svg"
  import InformationCircle from "@assets/images/icons/information-circle.svg"
  import { formatCurrency } from "@utils/currency"
  import { UserStore } from "@stores/user"
  import defaultCheckoutImage from "@assets/images/default-checkout-image@4x.png"
  import { simulateDiscount } from "@services/checkout"
  import Spinner from "@components/spinner"
  import { ErrorsStore } from "@stores/errors"
  import { onDestroy, onMount } from "svelte"
  import PolicyAndTerms from "@components/policy-and-terms"
  import { setCheckoutFields } from "@utils/checkout"
  import ChargeInfo from "@components/charge-info"
  import ProductItems from "@components/product-items"
  import PurchaseSubmit from "@components/purchase-submit"
  import Tooltip from "@components/tooltip"

  import {
    formatTimestampDate,
    formatShortDayOfWeek,
    formatLongDayOfWeek,
    formatHour,
    groupByWeekDays,
  } from "@utils/date"

  export let franchiseId
  export let groupSchedules = []
  export let material
  export let startDate
  export let subtotal
  export let productName
  export let productDescription
  export let productCategory
  export let installments
  export let productDiscount = 0
  export let productPromotionCode = ""
  export let productImage
  export let selectedPaymentMethod
  export let productChargeType
  export let hasGroup = false;
  export let productPrivacyUrl;
  export let productTermsUrl;
  export let shouldSupportCoupon = true;
  export let productItems = [];
  export let firstPaymentDate;
  export let formFields
  export let isOrder = false
  export let onSubmit = () => {}
  export let isDonation = false
  export let titleDetailsIndex

  let groupDaysSchedules = []

  let offsetHeight = 100
  let shouldShowChargeModal = false
  let hasNoCharge
  let shouldShowInstallments

  let couponInput
  let loading = false
  let userId = null

  const params = getUrlParams()
  const { state, franchisee_id } = params

  const userSub = UserStore.subscribe((data) => (userId = data?.id))

  const tryApplyCoupon = async (e) => {
    e.preventDefault()
    const { value } = couponInput
    const coupon = value.replace(/\s/g, "")
    if (coupon === "") return
    loading = true
    await simulateDiscount(coupon, userId, state, franchisee_id)
    loading = false
  }

  const clearCoupon = () => {
    setCheckoutFields({ simulateDiscount: {} })
  }

  const checkCoupon = (e) => {
    e.preventDefault()
    const { value } = e.target
    if (value?.trim() === "") {
      clearCoupon()
    }
  }

  const errorsSub = ErrorsStore.subscribe((error) => {
    if (error === "invalid-coupon") {
      if (couponInput) {
        couponInput.value = ""
        productPromotionCode = ""
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  })

  $: {
    if (offsetHeight) {
      const body = document.body
      body.style = `padding-bottom: ${offsetHeight}px`
    }
    hasNoCharge = subtotal - productDiscount === 0 || productDiscount > subtotal
    shouldShowInstallments =
      !hasNoCharge && installments?.selectedInstallment > 1
  }

  const removeCoupon = (e) => {
    e.preventDefault()
    clearCoupon()
  }
  onMount(() => {
    groupDaysSchedules = groupByWeekDays(groupSchedules)
  })

  onDestroy(() => {
    userSub()
    errorsSub()
  })
</script>

<div>
  <h3 class="section-title">{titleDetailsIndex}. Revisão {isDonation ? "" : "do pedido"}</h3>
  <div class="purchase-details">
    <h4 class="purchase-details-title">Detalhes da {isDonation ? "Doação" : "Compra"}</h4>
    <div class="course">
      <div class="course-img">
        <img src="{productImage || defaultCheckoutImage}" alt="{productName}" />
      </div>
      <div class="course-description">
        <span class="course-title">{productName}</span>
        <div class="course-complete-description">
          {#if productDescription}
            <span class="course-description-title">Descrição:</span><br />
            <div class="course-details-header">{productDescription}</div>
          {/if}
        </div>
      </div>
    </div>
    {#if hasGroup}
      <div class="course-details">
        <div class="course-title">
          <div class="course-icon">
            <img src="{AcademicCap}" alt="Academic Cap" />
          </div>
          <div>{productName}</div>
        </div>
        {#if material}
          <div class="course-material-outer">
            <div class="outer-icon">
              <div class="course-material-icon">
                <img src="{BookOpen}" alt="Book Open" />
              </div>
            </div>
            <div class="course-material-wrap">
              <div class="course-material-left">
                <div class="course-material-icon">
                  <img src="{BookOpen}" alt="Book Open" />
                </div>
                <div class="course-material-title">Material didático:</div>
              </div>
              <div class="course-material-right">
                <div class="course-material">
                  {#if productCategory === "ONLINE" && (franchisee_id === franchiseId || !franchisee_id) }
                    Receba seu livro gratuitamente em casa.
                  {:else}
                    Após confirmação da matrícula, aguarde o contato da nossa
                    equipe com as informações do seu material didático
                  {/if}
                </div>
              </div>
            </div>
          </div>
        {/if}
        <div class="course-date-outer">
          <div class="outer-icon">
            <div class="course-date-icon">
              <img src="{Calendar}" alt="Calendar" />
            </div>
          </div>
          <div class="course-date-wrap">
            <div class="course-date-left">
              <div class="course-date-icon">
                <img src="{Calendar}" alt="Calendar" />
              </div>
              <div class="course-date-title">Início do curso:</div>
            </div>
            <div class="course-date-right">
              <div class="course-date">
                {formatTimestampDate(startDate)}
              </div>
            </div>
          </div>
        </div>
        <div class="course-time-outer">
          <div class="outer-icon">
            <div class="course-time-icon">
              <img src="{Clock}" alt="Clock" />
            </div>
          </div>
          <div class="course-time-wrap">
            <div class="course-time-left">
              <div class="course-time-icon">
                <img src="{Clock}" alt="Clock" />
              </div>
              <div class="course-time-title">Dias e horários:</div>
            </div>
            <div class="course-time-right ">
              <div class="course-time">
                {#if groupDaysSchedules && groupDaysSchedules?.length}
                  <ul class="course-time-list">
                    {#each groupDaysSchedules as groupDaysSchedule}
                      <li>
                        <div class="course-time-list-content">
                          <div class="course-time-list-content-group">
                            <span class="course-day-of-week"
                              >{formatShortDayOfWeek(
                                groupDaysSchedule?.dayOfWeek
                              )}
                            </span>
                            -
                            <span
                              >{formatHour(groupDaysSchedule?.hourStart)} até às {formatHour(
                                groupDaysSchedule?.hourEnd
                              )}
                            </span>
                          </div>
                          <Tooltip>
                            <span>
                                {formatLongDayOfWeek(
                                  groupDaysSchedule?.dayOfWeek
                                )}
                              </span>
                              <br />
                              {#each groupDaysSchedule?.schedules as schedule}
                                <span>
                                  {formatHour(schedule?.hourStart)} às
                                  {formatHour(schedule?.hourEnd)}
                                </span>
                                <br />
                              {/each}
                          </Tooltip>
                        </div>
                      </li>
                    {/each}
                  </ul>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
    {#if shouldSupportCoupon}
      <div class="coupon">
        <input
          disabled="{productDiscount > 0}"
          bind:value="{productPromotionCode}"
          bind:this="{couponInput}"
          on:input="{checkCoupon}"
          type="text"
          placeholder="Cupom"
        />
        <button
          on:click="{(e) => {
            productDiscount > 0 ? removeCoupon(e) : tryApplyCoupon(e)
          }}"
          class="btn-coupon-apply {loading ? 'loading' : ''} {productDiscount > 0
            ? 'applied'
            : ''}"
        >
          {#if loading}
            <Spinner width="16" height="16" color="#40b6a6" />
          {:else}
            {productDiscount > 0 ? "Remover" : "Aplicar"}
          {/if}
        </button>
      </div>
    {/if}
    {#if !isOrder}
      <ProductItems
        productItems="{productItems}"
        firstPaymentDate="{firstPaymentDate}"
        installments="{installments}" />
    {/if}
    <div class="purchase-values">
      <div class="purchase-subtotal">
        {#if shouldSupportCoupon || isOrder}
          <div>
            <span class="purchase-subtotal-title">Investimento total</span>
            <span class="purchase-subtotal-value">{formatCurrency(subtotal)}</span
            >
          </div>
          <div>
            <span class="purchase-subtotal-coupon-title">
              {isOrder ? "Desconto" : "Cupom"}
            </span>
            <span class="purchase-subtotal-coupon-value"
              >-{productDiscount && productDiscount > 0
                ? formatCurrency(productDiscount)
                : "R$ 0,00"}</span
            >
          </div>
        {/if}
      </div>
    </div>
  </div>
  <div class="confirm-purchase" bind:offsetHeight>
    <div class="inner">
      <div class="purchase-total">
        <span class="purchase-total-title"
          >{installments?.selectedInstallment < 2 || hasNoCharge
            ? "Único"
            : "Mensal"}</span
        >
        <span class="purchase-total-value">
          {#if hasNoCharge}
            <div class="purchase-without-charge">
              <span class="purchase-total-value-info">de</span>
              <span class="purchase-total-value-diff"
                >{formatCurrency(subtotal)}</span
              >
              <span class="purchase-total-value-info">por</span>
              <span class="purchase-total-value-price">R$ 0,00</span>
            </div>
          {:else if productDiscount && productDiscount > 0}
            <span class="purchase-total-value-info">de</span>
            <span class="purchase-total-value-diff"
              >{formatCurrency(
                subtotal / installments?.selectedInstallment
              )}</span
            >
            <span class="purchase-total-value-info">por</span>
            <span class="purchase-total-value-price">
              {formatCurrency(
                (subtotal - productDiscount) / installments?.selectedInstallment
              )}
            </span>
          {:else}
            <span class="purchase-total-value-info">de</span>
            <span class="purchase-total-value-price"
              >{formatCurrency(
                subtotal / installments?.selectedInstallment
              )}</span
            >
          {/if}
          {#if shouldShowInstallments}
            <span class="purchase-total-installments"
              >por mês durante {installments?.selectedInstallment} meses</span
            >
          {/if}
          <small>Este é o total {isDonation ? "da sua doação" : "do seu investimento"}</small>
        </span>
      </div>
      {#if shouldShowInstallments && productChargeType === "TWO_ORDER" && selectedPaymentMethod === "CREDIT_CARD"}
        <span class="charge-info">
          <!-- svelte-ignore a11y-invalid-attribute -->
          Saiba mais sobre a cobrança mensal no seu cartão de crédito.
          <a on:click|preventDefault="{() => shouldShowChargeModal = true}" href="#">Clique aqui</a>
        </span>
      {/if}
      <PolicyAndTerms
        productPrivacyUrl="{productPrivacyUrl}"
        productTermsUrl="{productTermsUrl}"
      />
    </div>
    <PurchaseSubmit
        onSubmit="{onSubmit}"
        selectedPaymentMethod="{selectedPaymentMethod}"
        formFields="{formFields}"
        submitLabel="{isDonation ? 'Finalizar doação' : 'Finalizar compra'}"
      />
  </div>
</div>
{#if shouldShowChargeModal}
  <ChargeInfo onClose="{() => (shouldShowChargeModal = false)}" />
{/if}

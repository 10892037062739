<style lang="scss">
  @import "./franchisees-search.scss";
</style>

<script>
  import { onMount, onDestroy, tick } from "svelte"
  import { get } from "svelte/store"
  import { CheckoutStore } from "@stores/checkout"
  import { franchiseesSearch } from "@services/franchisee"
  import Spinner from "@components/spinner"
  
  export let titleFranchiseeIndex

  let ulList
  let debounce
  let timeout
  let franchiseesInputField
  let selectedFranchiseeName = ""
  let offset = 0
  let franchisees = []
  let filteredFranchisees = []
  let isLoading = true
  let showFranchiseesList = false
  let reachedAllFranchisees = false
  let noResults = false
  let isReachingMore = false
  let searchString = ""
  let selectedFranchisee = null
  let isSelectingFranchisee = false
  const perPage = 25
  let pagination = {
    first: perPage,
    offset: 0,
  }

  const getFranchisees = async () => {
    if (reachedAllFranchisees) return;
    isLoading = true;
    const data = await franchiseesSearch({pagination})
    isLoading = false
    if (data?.length === 0 && offset > 0) {
      reachedAllFranchisees = true
    } else {
      franchisees = franchisees.concat(data)
      filteredFranchisees = franchisees
    }
    offset += perPage;
    pagination = {
      ...pagination,
      offset,
    }
  }
  
  const searchFranchisees = async () => {
    searchString = franchiseesInputField.value.trim().toLowerCase()
    if (searchString) {
      filteredFranchisees = franchisees.filter(franchisee => franchisee?.name.toLowerCase().includes(searchString?.trim().toLowerCase()))
    } else {
      selectedFranchisee = null
      filteredFranchisees = franchisees
    }
    if (filteredFranchisees.length) return
    isLoading = true
    clearTimeout(debounce)
    noResults = false
    debounce = setTimeout(async () => {
      const data = await franchiseesSearch({search: searchString, pagination: {first: 100, offset: 0}})
      isLoading = false
      filteredFranchisees = data;
      if (!filteredFranchisees.length) noResults = true
    }, 600)
  }

  const reachMoreFranchisees = async () => {
    if (isReachingMore || searchString) return
    const { scrollTop, scrollHeight, clientHeight } = ulList
    const reachedBottom = Math.ceil(scrollTop) + clientHeight >= scrollHeight
    if (reachedBottom) {
      isReachingMore = true
      await getFranchisees()
      await tick()
      ulList?.scrollTo(0, scrollTop + 10)
      timeout = setTimeout(() => {
        isReachingMore = false
      }, 300)
    }
  }

  const validateSelectedFranchisee = () => {
    if (isSelectingFranchisee) return
    showFranchiseesList = false
    franchiseesInputField.classList.remove("error")
    if (!selectedFranchisee) franchiseesInputField.classList.add("error")
  }

  const setFranchisee = (franchisee) => {
    selectedFranchisee = franchisee
    selectedFranchiseeName = franchisee.name
    isSelectingFranchisee = showFranchiseesList = false
    const checkoutStore = get(CheckoutStore)
    const { fields } = checkoutStore
    CheckoutStore.set({
      ...checkoutStore,
      fields: {
        ...fields,
        franchiseeId: franchisee?.id,
      }
    })
  }

  onMount(() => {
    getFranchisees()
  })

  onDestroy(() => {
    clearTimeout(debounce)
    clearTimeout(timeout)
  })
</script>
<div class="franchisees-search">
  <h3 class="section-title">{titleFranchiseeIndex}. Escola</h3>
  <input
    placeholder="Selecione ou busque pelo nome da escola"
    bind:value="{selectedFranchiseeName}"
    type="text"
    name="franchisee-search"
    bind:this="{franchiseesInputField}"
    on:focus="{() => {showFranchiseesList = true; franchiseesInputField?.classList?.remove('error')}}"
    on:input="{searchFranchisees}"
    on:blur="{validateSelectedFranchisee}"
    on:click="{() => {showFranchiseesList = true}}"
    required
  />
  {#if showFranchiseesList}
    <ul bind:this="{ulList}" on:scroll={reachMoreFranchisees} class="franchisees-list">
      {#each filteredFranchisees as franchisee}
        <li class="franchisee">
          <button class="{selectedFranchisee?.id === franchisee?.id ? "selected-franchisee" : ""}" on:click|preventDefault="{() => {setFranchisee(franchisee)}}" on:mousedown|preventDefault="{() => {isSelectingFranchisee = true}}">
            {franchisee.name}
          </button>
        </li>
      {/each}
      {#if isLoading}
        <li class="loading-franchisees">
          <span>
            <Spinner width="16" height="16" />
          </span>
        </li>
      {/if}
      {#if noResults}
        <li class="no-franchisees-found">
          <span>Nenhuma escola encontrada</span>
        </li>
      {/if}
    </ul>
  {/if}
</div>

<style lang="scss">
  @import "./order.scss";
</style>

<script>
  import { onMount, onDestroy } from "svelte"
  import { getUrlParams } from "@utils/url"
  import { validateUUID } from "@utils/uuid"
  import Header from "@components/header"
  import Toast from "@components/toast"
  import PaymentForm from "@components/payment-form"
  import PurchaseDetails from "@components/purchase-details"
  import { getCustomerOrder } from "@services/order"
  import { customerOrder } from "@services/order"
  import { CheckoutStore } from "@stores/checkout"
  import Footer from "@components/footer"
  import Loading from "@components/loading"
  import { generateCaptchaToken } from "@integrations/google"
  import { getStudentMaterial } from "@utils/material"
  import CheckoutFail from "@components/checkout-fail"
  import { ProgressBarProgressStore } from "@stores/progress-bar-progress"
  import ProgressBar from "@components/progress-bar"
  import UserDetails from "@components/user-details"

  export let pageName = "order"

  let order
  let form
  let installments
  let group
  let chargeType
  let hasValidOrder = true
  let hasSubmitFail = false
  let isLoading = false
  let isSubmitting = false
  let customer = {}
  let student = {}
  let paymentMethod = {}
  let franchisee = {}
  let product = {}
  let productImage = {}
  let creditCard = {}
  let discount = 0
  let fullPrice
  let category
  let material
  let isOrderCompleted = false
  let productItems = []
  let firstPaymentDate
  let productTermsUrl
  let productPrivacyUrl

  const { VITE_WHATS_CONTACT_NUMBER } = import.meta.env

  const checkoutStoreSub = CheckoutStore.subscribe((data) => {
    const { fields } = data
    if (fields) ({ creditCard } = fields)
  })

  const getOrder = async (order_id) => {
    isLoading = true
    order = await getCustomerOrder(order_id)
    isLoading = false

    if (!order) return (hasValidOrder = false)
    ;({
      student,
      customer,
      franchisee,
      installments,
      group,
      paymentMethod: { paymentMethod },
      product,
      chargeType,
      discount,
      firstPaymentDate,
      fullPrice,
    } = order)

    const { status, items } = order

    if (status === "COMPLETED") {
      isOrderCompleted = true
      return
    }

    ({
      category: { category },
    } = product)

    const { VITE_DEFAULT_PRIVACY_URL, VITE_DEFAULT_TERMS_URL } = import.meta.env
    const {
      category: { privacyUrl, termsUrl },
    } = product

    productPrivacyUrl = privacyUrl ? privacyUrl : VITE_DEFAULT_PRIVACY_URL
    productTermsUrl = termsUrl ? termsUrl : VITE_DEFAULT_TERMS_URL

    installments = {
      selectedInstallment: installments,
    }

    const { images } = product

    productItems = items

    productItems = productItems
      .sort(
        (one, two) =>
          (one.productItem.productItemType === "STUDENT_BOOK") -
          (two.productItem.productItemType === "STUDENT_BOOK")
      )
      .map((item) => ({
        discount: item.discount ?? 0,
        price: item.price ?? 0,
        name: item.productItem?.name ?? "",
        productItemType: item.productItem?.productItemType ?? "",
      }))

    material = getStudentMaterial(productItems)

    if (images && images?.length > 0) {
      productImage = images.find((image) => image.resolution === "2x")
    }
  }

  const performRedirect = ({
    status,
    paymentData,
    paymentMethod,
    invoice,
    purchaseId,
  } = {}) => {
    const { VITE_COMMERCE_URL } = import.meta.env
    const redirectUrl = paymentData?.successUrl
      ? paymentData?.successUrl
      : VITE_COMMERCE_URL

    const invoicePayload = {
      ...paymentData,
      ...invoice,
      enableRedirect: false,
    }

    const retryPayload = {
      ...paymentData,
      productSuccessUrl: "/thank-you",
      purchaseId,
    }

    const param = pageName === "order" ? "?order_complete=true" : ""

    const urlPath = {
      CREDIT_CARD: {
        COMPLETED:
          paymentData?.accountStatus === "MISSING_PASSWORD"
            ? `${redirectUrl}auth/confirmpassword/${paymentData.userId}${param}`
            : "/thank-you",
        PARTIALLY_COMPLETED: `/retry?purchase=${btoa(
          JSON.stringify(retryPayload)
        )}`,
      },
      INVOICE: {
        WAITING_PAYMENT: `/invoice?purchase=${btoa(
          JSON.stringify(invoicePayload)
        )}`,
      },
    }

    return (location.href = urlPath[paymentMethod][status])
  }

  const submit = async () => {
    if (isSubmitting) return

    hasSubmitFail = false
    isSubmitting = true

    const { id } = order
    const { holder, cvv, cardNumber, expirationDate } = creditCard

    const paymentPayload = {
      CREDIT_CARD: {
        creditCard: {
          holder,
          securityCode: cvv,
          cardNumber,
          expirationDate,
        },
        orderId: id,
      },
      INVOICE: {
        orderId: id,
      },
    }

    const input = {
      ...paymentPayload[paymentMethod],
    }

    const token = await generateCaptchaToken()
    const response = await customerOrder(input, token)

    if (!response) {
      hasSubmitFail = true
      isSubmitting = false
      return
    }

    const { status, invoice, userId, price, accountStatus } = response
    const { category } = product
    const { privacyUrl, successUrl, termsUrl } = category
    const { selectedInstallment } = installments

    const paymentData = {
      productPrivacyUrl: privacyUrl,
      productSuccessUrl: successUrl,
      productTermsUrl: termsUrl,
      accountStatus,
      userId,
      productCategory: category?.category,
      installments: selectedInstallment,
      installmentValue: price / selectedInstallment,
    }

    return performRedirect({
      status,
      paymentData,
      paymentMethod,
      invoice,
      purchaseId: response?.id,
    })
  }

  onMount(async () => {
    ProgressBarProgressStore.set(66)
    const params = getUrlParams()
    const { order_id } = params
    hasValidOrder = validateUUID(order_id)
    if (!hasValidOrder) return
    await getOrder(order_id)
  })

  onDestroy(() => {
    checkoutStoreSub()
  })
</script>

<Header />
{#if isSubmitting}
  <Loading translucid />
{/if}
{#if !hasValidOrder}
  <Toast type="danger">
    <span>
      Desculpe, não é possível continuar porque a ordem para a rematrícula não é
      válida.
    </span>
  </Toast>
{:else if isLoading}
  <Loading />
{:else if isOrderCompleted}
  <Toast type="danger">
    <span>
      Desculpe, não é possível continuar porque a ordem para a rematrícula já
      foi concluída. Em caso de dúvidas entre em contato via WhatsApp <a
        href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"
        >clicando aqui</a
      >.
    </span>
  </Toast>
{:else}
  {#if pageName === "order"}
    <ProgressBar />
  {/if}
  <form novalidate bind:this="{form}" on:submit|preventDefault>
    {#if pageName === "re-enrollment"}
      <h1>Sua rematrícula</h1>
    {/if}
    <div class="order">
      <div class="left">
        <UserDetails
          user="{customer}"
          showAddress="{pageName === "order" && !!customer?.address}"
          title="1. Informação do responsável"
          addressLabel="Endereço de faturamento"
        />
        <UserDetails
          user="{student}"
          showAddress="{pageName === "order" && !!student?.address}"
          title="2. Informação do aluno"
          addressLabel="Endereço de envio do material didático"
        />
        <h2>
          3. Pagamento <small
            >Todas as transações são seguras e criptografadas.</small>
        </h2>
        <PaymentForm
          subtotal="{fullPrice}"
          shouldShowOnlyForm="{true}"
          shouldHideNotSelectedMethod="{true}"
          selectedPaymentMethod="{paymentMethod}" />
      </div>
      <div class="right">
        <PurchaseDetails
          shouldSupportCoupon="{false}"
          installments="{installments}"
          hasGroup="{group || false}"
          productDescription="{product?.description}"
          productName="{product?.name}"
          productImage="{productImage?.url}"
          franchiseId="{franchisee?.id}"
          selectedPaymentMethod="{paymentMethod}"
          productChargeType="{chargeType}"
          productDiscount="{discount}"
          subtotal="{fullPrice}"
          productCategory="{category}"
          material="{material}"
          groupSchedules="{group?.schedules}"
          startDate="{group?.startDate}"
          productItems="{productItems}"
          firstPaymentDate="{firstPaymentDate}"
          formFields="{form}"
          onSubmit="{submit}"
          isOrder="{pageName === 'order'}"
          productPrivacyUrl="{productPrivacyUrl}"
          productTermsUrl="{productTermsUrl}"
          titleDetailsIndex="{4}" />
      </div>
    </div>
  </form>
{/if}
{#if hasSubmitFail}
  <CheckoutFail
    whatsappNumber="{VITE_WHATS_CONTACT_NUMBER}"
    onClose="{() => (hasSubmitFail = false)}" />
{/if}
<Footer
  productPrivacyUrl="{productPrivacyUrl}"
  productTermsUrl="{productTermsUrl}" />

import { commerceClient } from "./graphql-clients"
import { SERVICE_QUERY } from "@graphql/service"
import { captureException } from "@integrations/sentry"

const getService = async (id) => {
  try {
    const response = await commerceClient.query({
      query: SERVICE_QUERY,
      variables: { id },
    })

    const {
      data: { service },
    } = response

    if (!service) throw response

    return service
  } catch (error) {
    captureException(error)
  }
}

export { getService }

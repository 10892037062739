<svg
  width="12"
  height="10"
  viewBox="0 0 12 10"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1.33334 5.6665L4 8.33317L10.6667 1.6665"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"></path>
</svg>

<style lang="scss">
  @import "./charge-info.scss";
</style>

<script>
  import Info from "@components/icons/info.svelte"
  import Modal from "@components/modal"
  export let onClose = () => {}
</script>

<Modal onClose="{onClose}">
  <div class="charge-info">
    <div class="charge-info-inner">
      <header>
        <Info />
        <h2>Informações de pagamento e mensalidade</h2>
      </header>
      <main>
        <p>
          O valor total do curso não compromete o limite do cartão de crédito,
          veja abaixo como funciona:
        </p>
        <p>
          Após efetuar a compra, você visualizará na fatura do cartão de crédito
          duas cobranças, que somado as parcelas, é o valor da mensalidade:
        </p>
        <p>
          <b>1° cobrança</b>
          Valor parcelado do seu material didático.
        </p>
        <p>
          <b>2° cobrança</b>
          Valor do seu curso cobrado individualmente a cada mês.
        </p>
      </main>
      <footer>
        <button on:click|preventDefault="{onClose}">Fechar</button>
      </footer>
    </div>
  </div>
</Modal>

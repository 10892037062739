import { DateTime, Settings, Info } from "luxon"
Settings.defaultZone = "America/Sao_Paulo"
Settings.defaultLocale = "pt-BR"

const formatTimestampDate = (timestamp) => {
  if (!timestamp) return null
  return DateTime.fromMillis(timestamp)
    .plus({ hours: 3 })
    .toFormat("dd/MM/yyyy")
}

const formatShortDayOfWeek = (weekDayNumber) => {
  if (!weekDayNumber) return null
  return Info.weekdays("short")[weekDayNumber - 1].slice(0, -1)
}

const formatLongDayOfWeek = (weekDayNumber) => {
  if (!weekDayNumber) return null
  return `${Info.weekdays("long")[weekDayNumber - 1].charAt(0).toUpperCase()}${Info.weekdays("long")[weekDayNumber - 1].slice(1)}`
}

const formatHour = (hour) => {
  if (!hour) return null
  return hour.slice(0, -3)
}

const groupByWeekDays = (days) => {
  if (!Array.isArray(days)) return []

  const groupedSchedules = days.reduce((grouped, day) => {
    const dayOfWeek = day.dayOfWeek
    if (!grouped[dayOfWeek]) {
      grouped[dayOfWeek] = {
        hourStart: day.hourStart,
        hourEnd: day.hourEnd,
        dayOfWeek,
        schedules: []
      }
    }
    grouped[dayOfWeek].schedules.push(day)
    return grouped
  }, {})

  return Object.values(groupedSchedules)
}

export {
  formatTimestampDate,
  formatShortDayOfWeek,
  formatLongDayOfWeek,
  formatHour,
  groupByWeekDays,
}

<style lang="scss">
  @import "./policy-and-terms.scss";
</style>

<script>
  import Checkbox from "@components/checkbox"
  export let productPrivacyUrl;
  export let productTermsUrl;
  let checkboxInputField = {}
</script>

<div class="checkbox-policy-terms">
  <Checkbox
    name="policy"
    id="policy"
    required
    bind:checkboxField="{checkboxInputField}"
    onInput="{() => checkboxInputField.classList.remove('error')}"
  >
    <span>
      Declaro que li e aceitei a
      <a
        class="policy-terms-link"
        href="{productPrivacyUrl}"
        target="_blank">Política de Privacidade</a
      >
      e os
      <a class="policy-terms-link" href="{productTermsUrl}" target="_blank"
        >Termos</a
      >.
    </span>
  </Checkbox>
</div>

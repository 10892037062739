<script>
  export let fillColor = "white"
</script>

<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.5 8.33329H17.5M5.83333 12.5H6.66667M10 12.5H10.8333M5 15.8333H15C16.3807 15.8333 17.5 14.714 17.5 13.3333V6.66663C17.5 5.28591 16.3807 4.16663 15 4.16663H5C3.61929 4.16663 2.5 5.28591 2.5 6.66663V13.3333C2.5 14.714 3.61929 15.8333 5 15.8333Z"
    stroke="{fillColor}"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"></path>
</svg>

// @ts-ignore
import App from "./App.svelte"
import overrideInsertRule from "./utils/overrides.js"
import "whatwg-fetch"
import * as Sentry from "@sentry/browser"
import { BrowserTracing } from "@sentry/tracing"

const { DEV, VITE_SENTRY_DSN } = import.meta.env

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: DEV ? 0 : 0.1,
  replaysOnErrorSampleRate: 1.0,
})

overrideInsertRule()

const app = new App({
  target: document.body,
})

export default app

<svg
  width="18"
  height="16"
  viewBox="0 0 18 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6.86293 0.5H6.32722C5.88525 0.5 5.52365 0.861607 5.52365 1.27344V14.6663C5.52365 15.1384 5.88525 15.5 6.29708 15.5H6.86293C7.3049 15.5 7.6665 15.1384 7.6665 14.6964V1.30357C7.6665 0.861607 7.3049 0.5 6.86293 0.5ZM3.88637 0.5C3.59173 0.5 3.38079 0.741071 3.38079 1.00558V14.9342C3.35066 15.2589 3.62186 15.5 3.88637 15.5C4.15088 15.5 4.42208 15.2589 4.42208 14.9944V1.00558C4.42208 0.741071 4.21115 0.5 3.88637 0.5ZM1.50579 0.5H0.970075C0.528111 0.5 0.166504 0.861607 0.166504 1.27344V14.6663C0.166504 15.1384 0.528111 15.5 0.939941 15.5H1.50579C1.94775 15.5 2.30936 15.1384 2.30936 14.6964V1.30357C2.30936 0.861607 1.94775 0.5 1.50579 0.5ZM16.5058 0.5H15.9701C15.5281 0.5 15.1665 0.861607 15.1665 1.30357V14.6964C15.1665 15.1384 15.5281 15.5 15.9701 15.5H16.5058C16.9478 15.5 17.3094 15.1384 17.3094 14.6964V1.30357C17.3094 0.861607 16.9478 0.5 16.5058 0.5ZM13.5594 0.5C13.2647 0.5 13.0236 0.741071 13.0236 1.03571V14.9643C13.0236 15.2589 13.2647 15.5 13.5594 15.5C13.854 15.5 14.0951 15.2589 14.0951 14.9643V1.03571C14.0951 0.741071 13.854 0.5 13.5594 0.5ZM10.0772 0.5H9.5415C9.09954 0.5 8.73793 0.861607 8.73793 1.30357V14.6964C8.73793 15.1384 9.09954 15.5 9.5415 15.5H10.0772C10.5192 15.5 10.8808 15.1384 10.8808 14.6964V1.30357C10.8808 0.861607 10.5192 0.5 10.0772 0.5Z"
    fill="white"></path>
</svg>

<script>
  export let fillColor = "#dc3545"
</script>

<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="32" height="32" rx="9" fill="white"></rect>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.2929 10.2929C10.6834 9.90237 11.3166 9.90237 11.7071 10.2929L16 14.5858L20.2929 10.2929C20.6834 9.90237 21.3166 9.90237 21.7071 10.2929C22.0976 10.6834 22.0976 11.3166 21.7071 11.7071L17.4142 16L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L16 17.4142L11.7071 21.7071C11.3166 22.0976 10.6834 22.0976 10.2929 21.7071C9.90237 21.3166 9.90237 20.6834 10.2929 20.2929L14.5858 16L10.2929 11.7071C9.90237 11.3166 9.90237 10.6834 10.2929 10.2929Z"
    fill="{fillColor}"></path>
</svg>

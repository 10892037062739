import { commerceClient } from "./graphql-clients"
import { FRANCHISEE_GROUP_QUERY, EXTERNAL_FRANCHISEE_QUERY, FRANCHISEES_SEARCH_QUERY } from "@graphql/franchisee"
import { FranchiseeGroupStore } from "@stores/franchisee-group"
import { captureException } from "@integrations/sentry"
import { setProduct } from "@utils/checkout"

const getFranchiseeGroup = async (groupId, state) => {
  try {
    if (!groupId || (groupId && groupId?.trim() === "")) throw "Invalid groupId"

    const groupData = await commerceClient.query({
      query: FRANCHISEE_GROUP_QUERY,
      variables: {
        id: groupId,
        state,
      },
    })

    const { data, errors } = groupData
    const { franchiseeGroup } = data

    if (errors) {
      throw errors
    }

    if (!franchiseeGroup) {
      throw "Schedule not found."
    }

    FranchiseeGroupStore.set({
      group: franchiseeGroup,
    })

    const { product, franchisee, id } = franchiseeGroup
    product["franchiseeId"] = franchisee?.id;
    product["groupId"] = id;
    setProduct(product);

    return franchiseeGroup
  } catch (error) {
    captureException(error)
  }
}

const getFranchiseeByExternalId = async (franchiseeExtenalId) => {
  try {
    if (!franchiseeExtenalId) return
    
    const response = await commerceClient.query({
      query: EXTERNAL_FRANCHISEE_QUERY,
      variables: {
        id: franchiseeExtenalId
      },
    })
    
    const { data: { externalFranchisee: { id } } } = response

    return id
  } catch(error) {
    captureException(error)
  }
}

const franchiseesSearch = async ({ 
  search,
  pagination = {
    first: 50,
    offset: 0
  }} = {}) => {
    try {
      const response = await commerceClient.query({
        query: FRANCHISEES_SEARCH_QUERY,
        variables: {
          search,
          pagination,
        },
      })
      const { data: { franchisees } } = response;
      return franchisees;
    } catch(error) {
      captureException(error)
    }
}

export {
  getFranchiseeGroup,
  getFranchiseeByExternalId,
  franchiseesSearch
}

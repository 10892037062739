import { gql } from "@apollo/client/core"

export const USER_QUERY = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
      phone
    }
  }
`

export const VERIFY_USER_EMAIL_QUERY = gql`
  query verifyUserEmail($email: String!) {
    verifyEmail(email: $email) {
      status
      userId
    }
  }
`

export const UPDATE_LIFE_CYCLE_MUTATION = gql`
  mutation updateUserLifeCycle($userId: ID!, $lifecycle: UserLifecycle!) {
    updateLifeCycle(input: { userId: $userId, lifecycle: $lifecycle })
  }
`

export const USER_EMAIL_QUERY = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      email
    }
  }
`

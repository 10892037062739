<script>
  export let width = 24;
  export let height = 24;
</script>
<svg
  width="{width}"
  height="{height}"
  viewBox="0 0 31 31"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2881_51139)">
    <path
      d="M4.06865 23.6962L4.13039 23.4802L4.00982 23.2906C2.58917 21.0564 1.76172 18.3761 1.76172 15.5001C1.76172 7.63523 7.92819 1.28717 15.5 1.28717C23.0719 1.28717 29.2383 7.63537 29.2383 15.494C29.2383 23.3588 23.0718 29.7069 15.5 29.7069C12.9301 29.7069 10.5247 28.9777 8.4682 27.705L8.28012 27.5886L8.06748 27.6494L2.4821 29.2483L4.06865 23.6962Z"
      fill="#25D366"
      stroke="white"></path>
    <path
      d="M23.3222 19.5385C23.3047 19.3448 23.1933 19.1753 23.0234 19.0965C21.875 18.5516 20.7207 18.0067 19.5722 17.4618C19.4433 17.4012 19.2851 17.4315 19.1914 17.5465L17.6328 19.363C17.5097 19.5083 17.3164 19.5567 17.1464 19.484C16.3379 19.1389 15.248 18.5577 14.1464 17.5768C12.8281 16.4083 12.0546 15.1549 11.6211 14.3376C11.8379 14.1862 12.4297 13.7321 12.6933 12.8905C12.6933 12.8844 12.6992 12.8784 12.6992 12.8784C12.8632 12.3577 12.8281 11.7946 12.6464 11.2799C12.3359 10.4262 11.7148 8.7733 11.4746 8.51901C11.4336 8.47662 11.3867 8.43424 11.3867 8.43424C11.2109 8.27076 10.9824 8.17994 10.7421 8.16783C10.6601 8.16178 10.5722 8.16178 10.4785 8.16178C10.2089 8.15573 9.96871 8.15573 9.79293 8.17994C9.29488 8.2526 8.9316 8.59772 8.70309 8.88834C8.4277 9.23346 8.09957 9.74811 7.88277 10.4262C7.8652 10.4868 7.84762 10.5413 7.83004 10.6018C7.58981 11.4616 7.63668 12.3819 7.92379 13.2296C8.19332 14.0227 8.5859 14.9673 9.1777 15.9663C10.1797 17.6676 11.2695 18.7212 12.0078 19.4174C12.8398 20.2106 13.8711 21.1854 15.5058 21.9967C17.0175 22.7475 18.3886 23.026 19.3086 23.1411C19.6836 23.1774 20.375 23.1835 21.166 22.8747C21.5293 22.7294 21.8339 22.5538 22.08 22.3661C22.7304 21.8878 23.1875 21.1673 23.3047 20.3559C23.3047 20.3499 23.3047 20.3438 23.3047 20.3378C23.3457 20.029 23.3398 19.7626 23.3222 19.5385Z"
      fill="white"></path>
  </g>
  <defs>
    <clipPath id="clip0_2881_51139">
      <rect
        width="30"
        height="31"
        fill="white"
        transform="translate(0.5 6.10352e-05)"></rect>
    </clipPath>
  </defs>
</svg>

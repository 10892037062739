<style lang="scss">
  @import "./service.scss";
</style>

<script>
  import { onMount } from "svelte"

  import Footer from "@components/footer"
  import Header from "@components/header"
  import Loading from "@components/loading"
  import ServiceDetails from "@components/service-details"
  import Toast from "@components/toast"
  import Tooltip from "@components/tooltip"
  import InvoiceDetails from "@components/invoice-details"
  import { formatCurrency } from "@utils/currency"
  import { getUrlParams } from "@utils/url"
  import { validateUUID } from "@utils/uuid"
  import { formatTimestampDate } from "@utils/date"
  import {  getService } from "@services/service"

  let service = null
  let hasValidService = true
  let isLoading = false
  let isServicePending = false
  let isInvoicePendent = false
  let productPrivacyUrl
  let productTermsUrl
  let franchisees = []

  const { VITE_WHATS_CONTACT_NUMBER } = import.meta.env

  const getServiceById = async (service_id) => {
    isLoading = true
    service = await getService(service_id)
    isLoading = false

    if (!service) return (hasValidService = false)

    const { status, invoice } = service
    if (status === "PENDING") {
      isServicePending = true
    }
    if (invoice?.status === "PENDENT") {
      isInvoicePendent = true
    }

    franchisees = service?.franchisees || []

    const { VITE_DEFAULT_PRIVACY_URL, VITE_DEFAULT_TERMS_URL } = import.meta.env

    productPrivacyUrl = VITE_DEFAULT_PRIVACY_URL
    productTermsUrl = VITE_DEFAULT_TERMS_URL
  }

  onMount(async () => {
    const params = getUrlParams()
    const { service_id } = params

    hasValidService = validateUUID(service_id)
    if (!hasValidService) return

    await getServiceById(service_id)
  })
</script>

<Header />

{#if !hasValidService}
  <Toast type="danger">
    <span>
      Desculpe, não é possível continuar porque o serviço não é válido.
    </span>
  </Toast>
{:else if isLoading}
  <Loading />
{:else if isServicePending}
  <Toast type="danger">
    <span>
      Desculpe, não é possível continuar porque esse serviço está pendente.
      Em caso de dúvidas entre em contato via WhatsApp <a
        href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"
        >clicando aqui</a>.
    </span>
  </Toast>
{:else if !isInvoicePendent}
  <Toast type="danger">
    <span>
      Desculpe, não é possível continuar porque o boleto deste serviço está em processamento ou indisponível.
      Em caso de dúvidas entre em contato via WhatsApp <a
        href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"
        >clicando aqui</a>.
    </span>
  </Toast>
{:else}
  <main class="main">
    <div class="service">
      <div class="left">
        <h2>Repasse do CNA Conecta</h2>
        <div class="details-box">
          <h3 class="datails-title">
            <span>Detalhes do Repasse</span>
            <Tooltip>
              <span>
                O detalhamento completo por<br />
                aluno está disponível<br />
                no CNA Box
              </span>
            </Tooltip>
          </h3>
          <div class="details-item">
            <span class="details-item-label">Período de Competência</span>
            <span class="details-item-value">
              {service?.startAt ? formatTimestampDate(service?.startAt) : ""}{service?.endAt
                ? ` - ${formatTimestampDate(service?.endAt)}`
                : ""}
            </span>
          </div>
          <div class="details-item">
            <span class="details-item-label">Vencimento</span>
            <span class="details-item-value">
              {formatTimestampDate(service?.dueDate)}
            </span>
          </div>
          <div class="services-list">
            {#each franchisees as franchisee}
              <ServiceDetails
                franchiseeName="{franchisee.franchisee.name}"
                value="{franchisee.servicePrice}"
                isMyOwnFranchise="{service.franchisee.id ===
                  franchisee.franchisee.id}"
              />
            {/each}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="sticky">
          <h2>Minha Unidade</h2>
          <div class="franchise">
            <h3 class="franchise-name">{service?.franchisee?.name}</h3>
          </div>
          <hr class="divider" />
          <div class="payment">
            <h2>Pagamento</h2>
            <p>Todas as transações são seguras e criptografadas.</p>
            <hr class="divider" />
            <InvoiceDetails invoice="{service?.invoice}" />
            <hr class="divider" />
            <div class="payment-details">
              <div class="details-item">
                <span class="details-item-label">Subtotal</span>
                <span class="details-item-value">
                  {formatCurrency(service?.servicePrice)}
                </span>
              </div>
              <div class="details-item">
                <span class="details-item-label">
                  Taxa Administrativa CNA Conecta
                </span>
                <span class="details-item-value">
                  {formatCurrency(service?.fee)}
                </span>
              </div>
              {#if service?.taxes && service?.taxes > 0}
                <div class="details-item">
                  <span class="details-item-label">
                    <span>Tributos a recolher</span>
                    <Tooltip>
                      <span>
                        Este valor refere-se aos tributos retidos <br />
                        pela fonte pagadora, como IR e CSRF (PIS, <br />
                        COFINS, CSLL), que são deduzidos do valor <br />
                        do serviço e recolhidos diretamente pela <br />
                        Escola tomadora do serviço.
                      </span>
                    </Tooltip>
                  </span>
                  <span class="details-item-value">
                    -{formatCurrency(service?.taxes)}
                  </span>
                </div>
              {/if}
              {#if service?.discount && service?.discount > 0}
                <div class="details-item">
                  <span class="details-item-label">
                    <span>Desconto</span>
                    <Tooltip>
                      <span>
                        Desconto sobre o serviço <br />
                        prestado aos seus <br />
                        próprios alunos
                      </span>
                    </Tooltip>
                  </span>
                  <span class="details-item-value">
                    -{formatCurrency(service?.discount)}
                  </span>
                </div>
              {/if}
              <div class="details-item">
                <span class="details-item-label summary">Total</span>
                <span class="details-item-value summary">
                  {formatCurrency(service?.price)}
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </main>
{/if}
<Footer
  productPrivacyUrl="{productPrivacyUrl}"
  productTermsUrl="{productTermsUrl}"
/>

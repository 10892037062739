<script>
  import Header from "@components/header/header.svelte"
  import LogoInstitute from "@assets/images/logo-instituto-cna.png"
  import Checkout from "@components/checkout/checkout.svelte"
</script>

<div class="donation-header">
  <Header logo="{LogoInstitute}" />
</div>

<h1>Faça sua doação e contribua hoje para o futuro que você sonha para as crianças do Capão Redondo/SP</h1>

<Checkout
  personalDocumentLabel="CPF/CNPJ"
  shouldSupportCoupon={false}
  isDonation={true}
  showInfoForBuyer={false}
/>

<style lang="scss">
  @import "donation.scss";
</style>

// https://github.com/sveltejs/svelte/issues/2358#issuecomment-485273213
const overrideInsertRule = () => {
  if (!(CSSStyleSheet && CSSStyleSheet.prototype.insertRule)) {
    return
  }

  const style = document.createElement("style")
  let shouldPrefixKeyframes = false

  document.body.appendChild(style)

  try {
    style.sheet.insertRule("@keyframes _ {}")
  } catch (err) {
    shouldPrefixKeyframes = true
  }

  document.body.removeChild(style)

  if (!shouldPrefixKeyframes) {
    return
  }

  const originalInsertRule = CSSStyleSheet.prototype.insertRule

  CSSStyleSheet.prototype.insertRule = function (rule, index) {
    let ruleValue = rule
    if (ruleValue.indexOf("@keyframes") === 0) {
      ruleValue = ruleValue.replace("@keyframes", "@-webkit-keyframes")
      try {
        originalInsertRule.call(this, ruleValue, index)
      } catch (err) {
        return err
      }
    } else {
      originalInsertRule.call(this, ruleValue, index)
    }
  }
}
export default overrideInsertRule

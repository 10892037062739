<svg
  width="26"
  height="26"
  viewBox="0 0 26 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 25.7999C20.0692 25.7999 25.7999 20.0692 25.7999 13C25.7999 5.93071 20.0692 0.199951 13 0.199951C5.93071 0.199951 0.199951 5.93071 0.199951 13C0.199951 20.0692 5.93071 25.7999 13 25.7999ZM10.9313 8.66858C10.3065 8.04374 9.29342 8.04374 8.66858 8.66858C8.04374 9.29342 8.04374 10.3065 8.66858 10.9313L10.7372 13L8.66858 15.0686C8.04374 15.6934 8.04374 16.7065 8.66858 17.3313C9.29342 17.9562 10.3065 17.9562 10.9313 17.3313L13 15.2627L15.0686 17.3313C15.6934 17.9562 16.7065 17.9562 17.3313 17.3313C17.9562 16.7065 17.9562 15.6934 17.3313 15.0686L15.2627 13L17.3313 10.9313C17.9562 10.3065 17.9562 9.29342 17.3313 8.66858C16.7065 8.04374 15.6934 8.04374 15.0686 8.66858L13 10.7372L10.9313 8.66858Z"
    fill="#DC3545"></path>
</svg>

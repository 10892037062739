<style lang="scss">
  @import "./checkout-fail.scss";
</style>

<script>
  import { onMount, onDestroy } from "svelte"
  import CardBlocked from "@assets/images/icons/card-blocked.svg"
  import CreditCard from "@components/icons/credit-card.svelte"
  import Close from "@components/icons/close.svelte"
  export let whatsappNumber
  export let code
  export let onClose = () => {}
  
  let errorDescription = ""
  let errorTitle = ""

  const errorContent = {
    "default": {
      "title": "Erro no processamento.",
      "description": "Não conseguimos efetuar a sua assinatura, por isso precisamos falar com você."
    },
    "product-out-of-stock": {
      "title": "Produto indisponível.",
      "description": "Produto indisponível para compra neste momento."
    }
  }

  const { description, title } = code && errorContent[code] || errorContent["default"]
  
  errorDescription = description
  errorTitle = title

  onMount(() => {
    document.documentElement.classList.add("overflow-hidden")
  })

  onDestroy(() => {
    document.documentElement.classList.remove("overflow-hidden")
  })
</script>

<div class="checkout-fail">
  <div class="checkout-fail-inner">
    <header>
      <div class="close">
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click="{onClose}">
          <Close />
        </a>
      </div>
      <img src="{CardBlocked}" alt="{errorTitle}" />
      <h1>Não foi possível processar o seu pagamento</h1>
    </header>
    <main>
      <h2>
        {errorTitle}
        <span>{errorDescription}</span>
      </h2>
      <div class="actions">
        <a
          href="https://api.whatsapp.com/send?phone={whatsappNumber}"
          target="_blank"
        >
          <CreditCard />
          <span>Fazer contato via Whatsapp</span>
        </a>
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href="#" on:click="{onClose}">
          <CreditCard fillColor="#40b6a6" />
          <span>Tentar novamente</span>
        </a>
      </div>
    </main>
  </div>
</div>

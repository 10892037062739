<style lang="scss">
  @import "./invoice-details.scss";
</style>

<script>
  import BarCode from "@components/icons/bar-code.svelte"
  import Checked from "@components/icons/checked.svelte"
  import { captureException } from "@integrations/sentry"
  import { lineFormat } from "@utils/invoice"

  export let invoice = {};

  let isLineCodeCopied = false

  const copyLineCode = async () => {
    try {
      await navigator.clipboard.writeText(lineFormat(invoice?.line))
      isLineCodeCopied = true
    } catch (error) {
      captureException(error)
    }
  }
</script>

<div class="invoice-details">
  <div class="invoice-content">
    <div class="bar-code">
      <img
        src="{invoice?.barcodeUrl}"
        alt="Código de barras do boleto"
      />
    </div>
    <div class="line-code">
      <div>Código do boleto:</div>
      <div>{lineFormat(invoice?.line)}</div>
    </div>
  </div>
  <div class="actions">
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a class="invoice-pdf" href="{invoice.pdfUrl}" target="_blank">
      <BarCode /><span>Visualizar boleto</span>
    </a>
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a
      href="#"
      class="{`invoice-line-code ${isLineCodeCopied ? 'success-copy' : ''}`}"
      on:click|preventDefault="{copyLineCode}"
    >
      {#if isLineCodeCopied}
        <Checked /><span>Código copiado</span>
      {:else}
        <BarCode /><span>Copiar código de barras</span>
      {/if}
    </a>
  </div>
</div>

<style lang="scss">
  @import "card-brand.scss";
</style>

<script>
  export let enabled = false

  import AmericanExpress from "@assets/images/card-brands/american-express.svg"
</script>

<img
  src="{AmericanExpress}"
  alt="American Express"
  class="card-brands {enabled ? 'enabled' : ''}"
/>

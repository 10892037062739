<style lang="scss">
  .cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>

<script>
  export let card = {}
  export let colored = false
  import AmericanExpress from "./american-express.svelte"
  import Visa from "./visa.svelte"
  import Elo from "./elo.svelte"
  import Hiper from "./hiper.svelte"
  import HiperCard from "./hipercard.svelte"
  import MasterCard from "./mastercard.svelte"
</script>

<div class="{`cards ${colored === true ? 'colored' : ''}`}">
  <Hiper enabled="{card.type === 'hiper' || colored}" />
  <HiperCard enabled="{card.type === 'hipercard' || colored}" />
  <Elo enabled="{card.type === 'elo' || colored}" />
  <AmericanExpress enabled="{card.type === 'american-express' || colored}" />
  <MasterCard enabled="{card.type === 'mastercard' || colored}" />
  <Visa enabled="{card.type === 'visa' || colored}" />
</div>

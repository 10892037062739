import { commerceClient } from "./graphql-clients"
import { PRODUCT_QUERY } from "@graphql/product"
import { captureException } from "@integrations/sentry"
import { setProduct } from "@utils/checkout"

const getProduct = async (productId, franchiseeId) => {
  try {
    const response = await commerceClient.query({
      query: PRODUCT_QUERY,
      variables: {
        id: productId,
        franchiseeId
      },
    });

    const { data: { product } } = response;
    
    if (!product || (product && product?.availableForSale === false)) throw "Product not found";
    
    product["franchiseeId"] = franchiseeId;
    product["groupId"] = null;
    
    setProduct(product);
    return product;
  } catch(error) {
    captureException(error);
  }
}

export { getProduct };

<script>
  import Header from "@components/header/header.svelte"
  import Checkout from "@components/checkout/checkout.svelte"
</script>

<Header />
<Checkout
  personalNameLabel="Nome do franqueado"
  personalDocumentLabel="CPF/CNPJ"
  showInfoForBuyer={false}
/>

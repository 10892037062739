import { checkoutClient } from "./graphql-clients"
import {
  PROMO_CODE_QUERY,
  CHECKOUT_MUTATION,
  RETRY_PURCHASE_MUTATION,
  PENDING_PURCHASE_QUERY,
} from "@graphql/checkout"
import { get } from "svelte/store"
import { captureException } from "@integrations/sentry"
import { ErrorsStore } from "@stores/errors"
import { getUrlParams } from "@utils/url"
import { getUser, updateUserLifeCycle } from "@services/user"
import { getExternalStudent } from "@services/student"
import { getFranchiseeGroup, getFranchiseeByExternalId } from "@services/franchisee"
import { CheckoutStore } from "@stores/checkout"
import { setCheckoutFields, trimWhiteSpaces } from "@utils/checkout"
import { getProduct } from "@services/product";

const simulateDiscount = async (promotionCode, userId, state, franchiseeId) => {
  try {
    if (!promotionCode || (promotionCode && promotionCode?.trim() === ""))
      return

    ErrorsStore.set(null)

    const productIds = []
    const { fields: { product: { productId } } } = get(CheckoutStore)

    productIds.push(productId)

    const { data } = await checkoutClient.query({
      query: PROMO_CODE_QUERY,
      variables: {
        userId,
        promotionCode,
        productIds,
        state,
        franchiseeId
      },
    })
    if (data && data?.simulateDiscount) {
      const { simulateDiscount } = data
      setCheckoutFields({ simulateDiscount: { ...simulateDiscount } })
    } else {
      throw new Error("invalid-coupon")
    }
  } catch (error) {
    const { message } = error

    if (message && message.indexOf("invalid-coupon") > -1) {
      ErrorsStore.set("invalid-coupon")
    }

    captureException(error)
  }
}

const purchase = async (input, token) => {
  let purchaseResult = {}
  
  const trimmedInput = trimWhiteSpaces(input)
  
  try {
    const { data } = await checkoutClient.mutate({
      mutation: CHECKOUT_MUTATION,
      variables: {
        input: trimmedInput,
      },
      context: {
        headers: { "X-Captcha": token, "x-captcha-version": "3" },
      },
    })
    
    const { checkout } = data
    
    if (!checkout) throw new Error("invalid-checkout-data")

    purchaseResult = {
      ...purchaseResult,
      ...checkout,
    }
  } catch (error) {
    const { message } = error
    purchaseResult = {
      ...purchaseResult,
      error: message,
    }
    captureException(error)
  }
  return purchaseResult
}

const retryPurchase = async (input) => {
  try {
    const response = await checkoutClient.mutate({
      mutation: RETRY_PURCHASE_MUTATION,
      variables: { input },
    })
    const {
      data: { retryPurchase },
    } = response
    return retryPurchase
  } catch (error) {
    captureException(error)
  }
}

const getPendingPurchase = async (id) => {
  try {
    const response = await checkoutClient.query({
      query: PENDING_PURCHASE_QUERY,
      variables: { id },
    })
    const {
      data: { pendingPurchase },
    } = response
    return pendingPurchase
  } catch (error) {
    captureException(error)
  }
}

const getProductData = async (productId, franchiseeExternalId) => {
  if (!productId) return;
  
  let franchiseeId

  if (franchiseeExternalId) {
    franchiseeId = await getFranchiseeByExternalId(franchiseeExternalId)
  }

  const product = await getProduct(productId, franchiseeId);
  
  if (!product) {
    ErrorsStore.set("invalid-product")
    setCheckoutFields({ initing: false })
    return
  }

  return product;
}

const getGroupData = async (groupId, state) => {
  if (!groupId) return;

  const group = await getFranchiseeGroup(groupId, state)
  
  if (!group) {
    ErrorsStore.set("invalid-group")
    setCheckoutFields({ initing: false })
    return
  }
  
  return group;
}

const initCheckout = async () => {
  const params = getUrlParams()
  const {
    group_id,
    state,
    user_id,
    promotion_code,
    charge_type,
    payment_method,
    product_id,
    franchisee_external_id,
    franchisee_id,
    external_student_id,
  } = params

  updateUserLifeCycle(user_id, "NEGOTIATION")

  setCheckoutFields({ initing: true })

  if (!group_id && !product_id) {
    ErrorsStore.set("invalid-group")
    setCheckoutFields({ initing: false })
    return
  }
  
  const productData = await getProductData(product_id, franchisee_external_id);
  const groupData = await getGroupData(group_id, state);
  if (!productData && !groupData) return;

  await simulateDiscount(promotion_code, user_id, state, franchisee_id)
  await getUser(user_id)
  await getExternalStudent(external_student_id)

  const checkoutStore = get(CheckoutStore)
  
  const {
    fields: { chargeTypes, product: { category: { defaultChargeType } } },
  } = checkoutStore
  
  const { types } = chargeTypes
  
  const hasValidChargeType = types.find((type) => type === charge_type)
  
  setCheckoutFields({
    initing: false,
    chargeTypes: {
      ...chargeTypes,
      selectedChargeType: hasValidChargeType ? charge_type : defaultChargeType,
    },
  })
}

export {
  simulateDiscount,
  purchase,
  initCheckout,
  retryPurchase,
  getPendingPurchase,
}

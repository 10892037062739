import { parseAsNumber } from "./number"
import { getUrlParams } from "@utils/url"
import { get } from "svelte/store"
import { CheckoutStore } from "@stores/checkout"
import { StudentStore } from "@stores/student"

const prepareCheckoutFields = (checkoutData) => {
  const student = get(StudentStore)
  const studentId = student?.id ?? undefined

  const checkoutStore = get(CheckoutStore)
  const { fields } = checkoutStore
  const previousSelectedFranchiseeId = fields?.franchiseeId
  const params = getUrlParams()
  const { state, franchisee_id } = params
  const {
    account,
    creditCard,
    simulateDiscount,
    product,
    chargeTypes,
  } = checkoutData
  const { paymentMethods } = product
  const { price } = simulateDiscount
  const { productId, installments, franchiseeId, groupId } = product
  const { selectedInstallment } = installments
  const { promotionCode } = simulateDiscount
  const phone = `+55${parseAsNumber(account?.phone)}`
  const cpf = parseAsNumber(account?.cpf)
  const cardNumber = parseAsNumber(creditCard?.cardNumber)
  const { available, selectedMethod } = paymentMethods
  const { selectedChargeType } = chargeTypes
  const paymentMethod = available.find(
    (method) => method.paymentMethod === selectedMethod
  )
  
  const shouldRemoveCreditCard =
    parseInt(price, 10) === 0 || selectedMethod === "INVOICE"
  
    const checkoutInputs = {
    creditCard: {
      holder: creditCard?.holder,
      cardNumber,
      securityCode: creditCard?.cvv,
      expirationDate: creditCard?.expirationDate,
    },
    account: {
      name: account?.name,
      email: account?.email,
      phone,
      cpf,
      receiveNotifications: false,
    },
    product: {
      productId,
      groupId,
    },
    installments: selectedInstallment,
    franchiseeId: previousSelectedFranchiseeId ? previousSelectedFranchiseeId : (franchisee_id || franchiseeId),
    promotionCode,
    state,
    chargeType: selectedChargeType,
    paymentMethodId: paymentMethod?.id,
    studentId,
  }

  if (shouldRemoveCreditCard) {
    delete checkoutInputs?.creditCard
  }

  return checkoutInputs
}

const redirect = ({accountStatus, userId, pending, successUrl} = {}) => {
  const { VITE_COMMERCE_URL } = import.meta.env
  
  if (successUrl) return window.location.replace(successUrl)

  const redirectUrl = VITE_COMMERCE_URL;
  const isPaymentPending = pending || false

  if (accountStatus !== "MISSING_PASSWORD")
    return window.location.replace(
      `${redirectUrl}auth/login?newBuy=true&pending=${isPaymentPending}`
    )

  return window.location.replace(
    `${redirectUrl}auth/confirmpassword/${userId}?newBuy=true&pending=${isPaymentPending}`
  )
}

const setCheckoutFields = (checkoutFields) => {
  const checkoutStore = get(CheckoutStore)
  const { fields } = checkoutStore

  CheckoutStore.set({
    ...checkoutStore,
    fields: {
      ...fields,
      ...checkoutFields,
      account: {
        ...fields.account,
        ...checkoutFields?.account,
      },
      creditCard: {
        ...fields.creditCard,
        ...checkoutFields?.creditCard,
      },
    },
  })
}

const getBase64PurchaseData = () => {
  const params = getUrlParams()
  const { purchase } = params
  if (!purchase) return history.go(-1)
  const purchaseData = atob(purchase)
  return JSON.parse(purchaseData)
}

const setProduct = product => {
  const { id, name, images, description, price, items, franchiseeId, groupId, category, paymentMethods, installments } = product;
  const defaultInstallments = installments;
  const params = getUrlParams()

  // TODO: Normalize these params name
  const { payment_method } = params

  const hasValidPaymentMethod = paymentMethods.find(
    (pm) => pm?.paymentMethod === payment_method
  )
  
  const currentPaymentMethod = hasValidPaymentMethod ? payment_method : "CREDIT_CARD";
  const productInstallments = getInstallments({paymentMethods, defaultInstallments, currentPaymentMethod});

  setCheckoutFields({
    product: {
      productId: id,
      franchiseeId,
      groupId,
      items: items || [],
      price,
      images,
      name,
      description,
      category,
      defaultInstallments,
      paymentMethods: {
        available: paymentMethods,
        selectedMethod: currentPaymentMethod,
      },
      installments: {
        ...productInstallments
      },
    },
  })
}

const getInstallments = ({paymentMethods, defaultInstallments, currentPaymentMethod} = {}) => {
  const installmentsByPaymentMethod = paymentMethods?.find(paymentMethod => paymentMethod.paymentMethod === currentPaymentMethod)?.installments

  if (installmentsByPaymentMethod) {
    return {
      total: installmentsByPaymentMethod,
      selectedInstallment: installmentsByPaymentMethod
    }
  }

  return {
    total: defaultInstallments,
    selectedInstallment: defaultInstallments
  }
}

const trimWhiteSpaces = (input) => {
  return Object.entries(input).reduce((trimmedInput, [key, value]) => {
    if (value === null || value === undefined) return trimmedInput
    if (typeof value === "object" && !Array.isArray(value)) {
      trimmedInput[key] = trimWhiteSpaces(value);
    } else if (typeof value === "string") {
      trimmedInput[key] = value.trim();
    } else {
      trimmedInput[key] = value;
    }
    return trimmedInput;
  }, {});
}

export {
  prepareCheckoutFields,
  redirect,
  setCheckoutFields,
  getBase64PurchaseData,
  setProduct,
  getInstallments,
  trimWhiteSpaces
}

import { writable } from "svelte/store"

const domainsList = [
  "gmail.com",
  "hotmail.com",
  "outlook.com",
  "live.com",
  "yahoo.com",
  "icloud.com",
  "aol.com",
  "me.com",
  "msn.com",
  "aim.com",
  "mail.com",
  "googlemail.com",
  "uol.com.br",
  "terra.com.br",
]

const MatchedDomainsStore = writable([])

export { MatchedDomainsStore, domainsList }

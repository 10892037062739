import { writable } from "svelte/store"

export let CheckoutStore = writable({
  fields: {
    account: {},
    creditCard: {},
    product: {},
    installments: {},
    paymentMethods: {},
    simulateDiscount: {},
    pendingPurchases: {},
    chargeTypes: {
      types: ["TWO_ORDER", "STANDARD"],
      selectedChargeType: "TWO_ORDER",
    },
    initing: true,
    groupId: undefined,
    state: undefined,
    franchiseeId: undefined,
    paymentMethodId: undefined,
  },
})

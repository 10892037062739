<style lang="scss">
  @import "./user-details.scss";
</style>

<script>
  import { cpfFormatter } from "@utils/document"
  import { formatAddress } from "@utils/address"
  import { phoneFormatter } from "@utils/phone"

  export let user = {};
  export let showAddress = false;
  export let title = "";
  export let addressLabel = "";
</script>

<h2>{title}</h2>
<div class="user">
  <div class="user-fields">
    <div>
      <h3>Nome</h3>
      <div class="user-name">
        {user?.name}
      </div>
    </div>
    <div>
      <h3>E-mail</h3>
      <div class="user-email">
        {user?.email}
      </div>
    </div>
  </div>
  <div class="user-fields">
    <div>
      <h3>CPF</h3>
      <div class="user-cpf">
        {cpfFormatter(user?.cpf)}
      </div>
    </div>
    <div>
      <h3>Telefone</h3>
      <div class="user-phone">
        {phoneFormatter(user?.phone)}
      </div>
    </div>
  </div>
  {#if showAddress}
    <div class="user-fields">
      <div>
        <h3>{addressLabel}</h3>
        <div class="user-address">
          {formatAddress(user?.address).firstLine}
        </div>
        <div class="user-address">
          {formatAddress(user?.address).secondLine}
        </div>
      </div>
    </div>
  {/if}
</div>

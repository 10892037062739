/* eslint-disable */

const { VITE_RECAPTCHA_KEY } = import.meta.env

window.dataLayer = window.dataLayer || []

const generateCaptchaToken = async () => {
  if (!grecaptcha) return
  return new Promise((resolve, reject) => {
    try {
      grecaptcha.enterprise.ready(async () => {
        try {
          const token = await grecaptcha.enterprise.execute(VITE_RECAPTCHA_KEY)
          resolve(token)
        } catch {
          reject()
        }
      })
    } catch {
      reject()
    }
  })
}

export { generateCaptchaToken, VITE_RECAPTCHA_KEY }

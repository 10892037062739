<style lang="scss">
  @import "payment-form.scss";
</style>

<script>
  import PaymentMethods from "@components/payment-methods"
  import PaymentFormInputs from "@components/payment-form-inputs"
  import Installments from "@components/installments"
  export let freeCharge = false
  export let selectedPaymentMethod
  export let subtotal
  export let installments = {}
  export let productDiscount = 0
  export let creditCard = {}
  export let shouldShowOnlyForm = false
  export let shouldHideNotSelectedMethod = false
  export let isDonation = false
  export let titlePaymentIndex
  export let titleInstallmentsIndex

  let shouldShowInstallments = false
  let card = {}

  const setCardBrand = (type) => {
    card = { type }
  }

  $: {
    shouldShowInstallments =
      installments && installments?.total > 1 ? true : false
  }
</script>

<div class="payment">
  {#if !shouldShowOnlyForm}
    <h3 class="section-title">{titleInstallmentsIndex}. Parcelamento</h3>
    {#if shouldShowInstallments}
      <h4 class="section-info">Em quantas parcelas você gostaria de pagar?</h4>
    {/if}
    <Installments
      subtotal="{subtotal}"
      productDiscount="{productDiscount}"
      installments="{installments}"
      freeCharge="{freeCharge}"
      shouldShowInstallments="{shouldShowInstallments}"
      isDonation="{isDonation}"
      />
    <h3 class="section-title">{titlePaymentIndex}. Formas de pagamento</h3>
    <span class="subtitle"
      >Todas as transações são seguras e criptografadas.</span>
  {/if}
  <div class="payment-card component">
    <PaymentMethods
      selectedPaymentMethod="{selectedPaymentMethod}"
      card="{card}"
      shouldHideNotSelectedMethod="{shouldHideNotSelectedMethod}">
      <PaymentFormInputs
        setCardBrand="{setCardBrand}"
        freeCharge="{freeCharge}"
        selectedPaymentMethod="{selectedPaymentMethod}"
        creditCard="{creditCard}" />
    </PaymentMethods>
  </div>
</div>

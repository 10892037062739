<script>
  export let fillColor = "white"
  export let width = 30
  export let height = 30
</script>

<svg
  width="{width}"
  height="{height}"
  viewBox="0 0 30 30"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.43934 6.43934C7.02513 5.85355 7.97487 5.85355 8.56066 6.43934L15 12.8787L21.4393 6.43934C22.0251 5.85355 22.9749 5.85355 23.5607 6.43934C24.1464 7.02513 24.1464 7.97487 23.5607 8.56066L17.1213 15L23.5607 21.4393C24.1464 22.0251 24.1464 22.9749 23.5607 23.5607C22.9749 24.1464 22.0251 24.1464 21.4393 23.5607L15 17.1213L8.56066 23.5607C7.97487 24.1464 7.02513 24.1464 6.43934 23.5607C5.85355 22.9749 5.85355 22.0251 6.43934 21.4393L12.8787 15L6.43934 8.56066C5.85355 7.97487 5.85355 7.02513 6.43934 6.43934Z"
    fill="{fillColor}"></path>
</svg>

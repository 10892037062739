<style lang="scss">
  @import "./loading.scss";
</style>

<script>
  export let translucid = false
  import Spinner from "../spinner"
</script>

<div class="loading {translucid ? 'translucid' : ''}" on:click|stopPropagation>
  <Spinner />
</div>

<svg
  width="62"
  height="62"
  viewBox="0 0 62 62"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M61.4001 31.0001C61.4001 47.7896 47.7896 61.4001 31.0001 61.4001C14.2106 61.4001 0.600098 47.7896 0.600098 31.0001C0.600098 14.2106 14.2106 0.600098 31.0001 0.600098C47.7896 0.600098 61.4001 14.2106 61.4001 31.0001ZM34.8001 15.8001C34.8001 17.8988 33.0988 19.6001 31.0001 19.6001C28.9014 19.6001 27.2001 17.8988 27.2001 15.8001C27.2001 13.7014 28.9014 12.0001 31.0001 12.0001C33.0988 12.0001 34.8001 13.7014 34.8001 15.8001ZM27.2001 27.2001C25.1014 27.2001 23.4001 28.9014 23.4001 31.0001C23.4001 33.0988 25.1014 34.8001 27.2001 34.8001L27.2001 46.2001C27.2001 48.2988 28.9014 50.0001 31.0001 50.0001H34.8001C36.8988 50.0001 38.6001 48.2988 38.6001 46.2001C38.6001 44.1014 36.8988 42.4001 34.8001 42.4001V31.0001C34.8001 28.9014 33.0988 27.2001 31.0001 27.2001H27.2001Z"
    fill="white"></path>
</svg>

<style lang="scss">
  @import "./toast.scss";
</style>

<script>
  import Close from "@components/icons/close.svelte"
  export let type = ""
  export let onClose = null
  export let title = ""
  export let icon = null

  const closeToast = (e) => {
    e.preventDefault()
    onClose()
  }
</script>

<div class="toast {type}">
  <div class="toast-inner">
    {#if icon !== null}
      <div class="toast-icon">
        {#if onClose !== null}
          <button class="toast-close" on:click="{closeToast}">
            <svelte:component this="{icon}" />
          </button>
        {:else}
          <svelte:component this="{icon}" />
        {/if}
      </div>
    {/if}
    <main>
      {#if title !== ""}
        <h3>{title}</h3>
      {/if}
      <slot />
    </main>
    {#if onClose !== null}
      <button class="toast-close" on:click="{closeToast}">
        <Close width="20" height="20" fillColor="#6b7280" />
      </button>
    {/if}
  </div>
</div>

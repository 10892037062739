<style lang="scss">
  @import "./product-items.scss";
</style>
<script>
  import { formatCurrency } from "@utils/currency";
  import { formatTimestampDate } from "@utils/date";
  export let productItems = [];
  export let firstPaymentDate;
  export let installments = {
    selectedInstallments: 0
  };

  let selectedInstallment;

  ({selectedInstallment} = installments)
  
  const course = "Curso (estágio)";
  const book = "Material Didático"

  const itemType = {
    PRESENTIAL: course,
    ONLINE: course,
    STUDENT_BOOK: book,
  }

  const isCourse = {
    PRESENTIAL: true,
    ONLINE: true,
  };
</script>
{#if productItems.length}
  <div class="product-items">
    {#each productItems as productItem}
      <div class="product-item">
        <div class="product-item-type">
          <div class="product-item-type-description">
            {itemType[productItem?.productItemType]}
          </div>
          <div class="product-item-price">
            <div class="product-item-currency">
              {#if productItem?.discount > 0 }
                <div class="product-item-discount">
                  de <span>{formatCurrency((productItem?.discount + productItem?.price) / selectedInstallment)}</span> por
                </div>
              {/if}
              {formatCurrency(productItem?.price / selectedInstallment)}
            </div>
            <div class="product-item-installments">
              {#if selectedInstallment > 1}
                por mês, durante {selectedInstallment} meses
              {:else}
                Pagamento à vista
              {/if}</div>
          </div>
        </div>
      </div>
      <div class="product-item-charge">
        {#if isCourse[productItem?.productItemType] && firstPaymentDate}
          Sua cobrança será realizada no dia {formatTimestampDate(firstPaymentDate)}
        {:else}
          A cobrança do material didático será realizada na finalização do pedido
        {/if}
      </div>
    {/each}
  </div>
{/if}

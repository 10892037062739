<style lang="scss">
  @import "contact-form.scss";
</style>

<script>
  import IMask from "imask"
  import { UserStore } from "@stores/user"
  import { onDestroy, onMount, tick } from "svelte"
  import { cpf, cnpj } from "cpf-cnpj-validator"
  import { validateEmailFormat } from "@utils/email"
  import Stop from "@components/icons/stop.svelte"
  import { verifyUserEmail, updateUserLifeCycle } from "@services/user"
  import Spinner from "@components/spinner/spinner.svelte"
  import Toast from "@components/toast/toast.svelte"
  import CloseRounded from "@components/icons/close-rounded.svelte"
  import Success from "@components/icons/success.svelte"
  import { MatchedDomainsStore, domainsList } from "@stores/domains"
  import { setCheckoutFields } from "@utils/checkout"
  import { parseAsNumber } from "@utils/number"
  import { getInputValue } from "@utils/form"

  export let account = { email: "", cpf: "", name: "", phone: "" }
  export let personalNameLabel = "Nome"
  export let personalDocumentLabel = "CPF"
  export let showInfoForBuyer = true;
  export let isDonation = false;
  export let titleContactIndex;

  let matchedDomains = []
  let currentEmail = ""
  let isValidatingEmail = false
  let isExtraValidationEmailFailed = false
  let nameInputField
  let emailInputField
  let cpfCnpjInputField
  let phoneInputField
  let emailName = ""

  let phoneMaskRef
  let cpfCnpjMaskRef

  const userSub = UserStore.subscribe(async (data) => {
    if (Object.keys(data).length > 0) {
      const { name, email, phone, cpf } = data
      account = {
        ...account,
        name,
        email,
        phone,
        cpf,
      }

      await tick()

      if (account.email) addEmailFieldCSSClass("success")

      nameInputField.value = account.name || "";
      emailInputField.value = account.email || "";
      cpfCnpjInputField.value = account.cpf || "";

      phoneMaskRef.typedValue = account.phone
      setCheckoutFields({
        account: { ...account, phone: phoneMaskRef.typedValue },
      })
    }
  })

  const cpfOptions = {
    mask: "000.000.000-00",
  }

  const cnpjOptions = {
    mask: "00.000.000/0000-00",
  }

  const phoneOptions = {
    mask: "(00) 00000-0000",
  }

  const resetMatchedDomainsStore = () => MatchedDomainsStore.set([])

  const getEmailFieldParams = () => {
    const { classList } = emailInputField || {}
    return { classList }
  }

  const addEmailFieldCSSClass = (cssClass) => {
    const { classList } = getEmailFieldParams()
    if (!classList) return
    removeEmailFieldCSSClasses()
    classList.add(cssClass)
  }

  const doExternalEmailValidation = async (email) => {
    if (currentEmail === email) return
    removeEmailFieldCSSClasses()
    isValidatingEmail = true

    const userData = await verifyUserEmail(email)

    isValidatingEmail = false

    if (!userData) return

    const { userId, status } = userData

    if (status === "INVALID_EMAIL") {
      addEmailFieldCSSClass("error")
      isExtraValidationEmailFailed = true
      currentEmail = ""
    } else {
      addEmailFieldCSSClass("success")
      isExtraValidationEmailFailed = false
      currentEmail = email
      if (userId) updateUserLifeCycle(userId, "NEGOTIATION")
    }
  }

  const removeEmailFieldCSSClasses = () => {
    const { classList } = getEmailFieldParams()
    classList.remove("error", "success")
  }

  const validateEmail = async (event) => {
    setTimeout(async () => {
      const email = await getInputValue(event)
      if (!email) return
      const isEmailValid = validateEmailFormat(email)
      if (!isEmailValid) {
        addEmailFieldCSSClass("error")
        currentEmail = ""
      } else {
        await doExternalEmailValidation(email)
      }
    }, 120)
  }

  const cpfCnpjValidation = (cpfCnpj, validate) => {
    if (!cpfCnpj) return

    if (validate) {
      const isCPFValid = cpf.isValid(cpfCnpj)
      const isCNPJValid = cnpj.isValid(cpfCnpj)

      if (!isCPFValid && !isCNPJValid) {
        cpfCnpjInputField?.classList?.toggle("error")
      }
    } else {
      if (parseAsNumber(cpfCnpj).length <= 11) {
        cpfCnpjMaskRef = IMask(cpfCnpjInputField, cpfOptions).destroy()
      } else {
        cpfCnpjMaskRef = IMask(cpfCnpjInputField, cnpjOptions).destroy()
      }
    }
  }

  const tryEmailCompletion = async (email) => {
    let emailStrings = ""
    let filteredDomains

    if (!email || email?.indexOf("@") === -1) return

    emailStrings = email.split("@")
    emailName = emailStrings[0]

    if (!emailName) return

    if (!emailStrings[1]) {
      filteredDomains = domainsList.slice(0, 5)
    } else {
      filteredDomains =
        domainsList.filter(
          (domain) => domain.indexOf(emailStrings[1]) !== -1
        ) || []
      if (
        filteredDomains.length === 1 &&
        filteredDomains[0] === emailStrings[1]
      ) {
        filteredDomains = []
      }
    }
    MatchedDomainsStore.set(filteredDomains)
  }

  const matchEmailDomain = async (e, domain) => {
    e.preventDefault()
    const email = `${emailName}@${domain}`
    emailInputField.value = email
    setCheckoutFields({
      account: {
        email
      },
    })
    resetMatchedDomainsStore()
  }

  const onClose = () => (isExtraValidationEmailFailed = false)

  const matchedDomainsSub = MatchedDomainsStore.subscribe((data) => {
    matchedDomains = data
  })

  const setAccountName = async (event) => {
    const name = await getInputValue(event)
    setCheckoutFields({
      account: {
        name,
      },
    })
  }

  const setAccountEmail = async (event) => {
    const email = await getInputValue(event)
    tryEmailCompletion(email)
    setCheckoutFields({
      account: {
        email,
      },
    })
  }

  const setAccountCpf = async (event) => {
    const cpf = await getInputValue(event)
    setCheckoutFields({
      account: {
        cpf
      }
    })
  }

  const setAccountPhone = async (event) => {
    const phone = await getInputValue(event)
    setCheckoutFields({
      account: {
        phone
      }
    })
  }

  document.body.addEventListener("click", resetMatchedDomainsStore)

  onMount(() => {
    phoneMaskRef = IMask(phoneInputField, phoneOptions)
  })

  onDestroy(() => {
    userSub()
    matchedDomainsSub()
    document.body.removeEventListener("click", resetMatchedDomainsStore)
  })
</script>

<h3 class="section-title">{titleContactIndex}. Informações do {isDonation ? "doador" : "comprador"}</h3>
<div class="info">
  {#if isExtraValidationEmailFailed}
    <Toast
      type="danger"
      onClose="{onClose}"
      title="Email inválido"
      icon="{CloseRounded}">
      <span
        >Você precisa adicionar um email valido para continuar o pagamento.</span>
    </Toast>
  {/if}
</div>
<div class="contact-form component">
  <div class="contact-form-field">
    <label for="name-id">{personalNameLabel}</label>
    <input
      bind:this="{nameInputField}"
      required
      id="name-id"
      class="focused sentry-mask"
      type="text"
      placeholder="{personalNameLabel}"
      on:paste="{setAccountName}"
      on:keyup="{setAccountName}"
      on:focus="{nameInputField?.classList?.remove('error')}" />
  </div>
  <div class="contact-form-field">
    {#if isValidatingEmail}
      <div class="veryfing-email">
        <Spinner width="16" height="16" />
      </div>
    {/if}
    <label for="email-id">E-mail</label>
    <input
      class="sentry-mask"
      id="email-id"
      type="email"
      placeholder="E-mail"
      required
      bind:this="{emailInputField}"
      on:focus="{emailInputField?.classList?.remove('error')}"
      on:blur="{validateEmail}"
      on:paste="{setAccountEmail}"
      on:keyup="{setAccountEmail}" />
    {#if !isValidatingEmail}
      <div class="error-info"><Stop /></div>
      <div class="success-info"><Success /></div>
    {/if}
    {#if matchedDomains.length > 0}
      <div class="email-domains" on:click="{(e) => e.stopPropagation()}">
        <div class="email-domains-inner">
          {#each matchedDomains as domain}
            <button
              class="email-domain"
              on:click="{(e) => matchEmailDomain(e, domain)}">{domain}</button>
          {/each}
        </div>
      </div>
    {/if}
  </div>
  <div class="contact-form-field">
    <label for="cpf-id">{personalDocumentLabel}</label>
    <input
      class="sentry-mask"
      bind:this="{cpfCnpjInputField}"
      required
      id="cpf-id"
      type="text"
      placeholder="{personalDocumentLabel}"
      on:input="{cpfCnpjValidation(cpfCnpjInputField.value, false)}"
      on:blur="{cpfCnpjValidation(cpfCnpjInputField.value, true)}"
      on:focus="{cpfCnpjInputField?.classList?.remove('error')}"
      on:paste="{setAccountCpf}"
      on:keyup="{setAccountCpf}" />
  </div>
  <div class="contact-form-field">
    <label for="phone-id">Celular</label>
    <input
      class="sentry-mask"
      bind:this="{phoneInputField}"
      required
      id="phone-id"
      type="text"
      placeholder="(xx) xxxxx-xxxx"
      on:focus="{phoneInputField?.classList?.remove('error')}"
      on:paste="{setAccountPhone}"
      on:keyup="{setAccountPhone}" />
  </div>
</div>
{#if showInfoForBuyer}
  <div class="contact-form-info">
    <span class="important-info">*</span>Os dados informados acima são referentes
    ao comprador, podendo a compra ser de aquisição própria ou para seus dependentes.
  </div>
{/if}

<style lang="scss">
  @import "./retry.scss";
</style>

<script>
  import TwoPaymentsFail from "@components/icons/two-payments-fail.svelte"
  import Danger from "@components/icons/danger.svelte"
  import Checked from "@components/icons/success.svelte"
  import PaymentFormInputs from "@components/payment-form-inputs"
  import CardBrands from "@components/card-brands"
  import Checkbox from "@components/checkbox/checkbox.svelte"
  import CreditCard from "@components/icons/credit-card.svelte"
  import WhatsOutline from "@components/icons/whats-outline.svelte"
  import { onMount, onDestroy } from "svelte"
  import { formatCurrency } from "@utils/currency"
  import { ProgressBarProgressStore } from "@stores/progress-bar-progress"
  import Loading from "@components/loading"
  import ChargeInfo from "@components/charge-info"
  import { CheckoutStore } from "@stores/checkout"
  import { retryPurchase, getPendingPurchase } from "@services/checkout"
  import { shouldSubmitForm } from "@utils/form"
  import Toast from "@components/toast"
  import CheckoutFail from "@components/checkout-fail"
  import { redirect, getBase64PurchaseData } from "@utils/checkout"
  import { parseAsNumber } from "@utils/number"
  import Header from "@components/header"
  import ProgressBar from "@components/progress-bar"
  import Footer from "@components/footer"

  const { VITE_WHATS_CONTACT_NUMBER } = import.meta.env

  let pendingPurchases
  let loading = false
  let installments
  let creditCard
  let payments = [{}]
  let shouldShowChargeModal = false
  let shouldShowPaymentInfo = false
  let useSameCard = false
  let retryFormFields
  let checkoutFields
  let isSubmitting = false
  let hasSubmitError = false
  let productPrivacyUrl
  let productTermsUrl
  let firstPaid
  let firstFailed

  const products = {
    student_book: "Material didático",
    presential: "Curso",
    online: "Curso online",
  }

  let checkboxSameCardInput = {}

  const checkoutStoreSub = CheckoutStore.subscribe((data) => {
    const { fields } = data
    checkoutFields = fields
  })

  const getPending = async () => {
    const { purchaseId } = getBase64PurchaseData()
    installments = getBase64PurchaseData()?.installments
    pendingPurchases = await getPendingPurchase(purchaseId)
    payments = pendingPurchases?.payments
    if (!payments || payments?.length === 0) return
    firstPaid = payments.find(payment => payment.status === "PAID");
    firstFailed = payments.find(payment => payment.status === "RETRY");
    creditCard = pendingPurchases?.creditCard
  }

  const retryPending = async () => {
    if (!shouldSubmitForm(retryFormFields)) return

    const { creditCard } = checkoutFields
    const { purchaseId, accountStatus, userId, productCategory, productSuccessUrl } = getBase64PurchaseData()
    let response

    hasSubmitError = false
    isSubmitting = true

    if (useSameCard) {
      response = await retryPurchase({
        creditCardId: pendingPurchases?.creditCard?.id,
        purchaseId,
      })
    } else {
      const { holder, cvv, cardNumber, expirationDate, useAsDefault } =
        creditCard
      response = await retryPurchase({
        creditCard: {
          holder,
          securityCode: cvv,
          expirationDate,
          useAsDefault: useAsDefault || false,
          cardNumber: parseAsNumber(cardNumber),
        },
        purchaseId,
      })
    }

    if (!response) {
      isSubmitting = false
      hasSubmitError = true
    } else {
      redirect({
        accountStatus,
        userId,
        category: productCategory,
        successUrl: productSuccessUrl
      })
    }
  }

  onMount(async () => {
    ProgressBarProgressStore.set(66)
    loading = true
    await getPending()
    productPrivacyUrl = getBase64PurchaseData()?.productPrivacyUrl,
    productTermsUrl = getBase64PurchaseData()?.productTermsUrl
    loading = false
  })

  onDestroy(() => {
    checkoutStoreSub()
  })
</script>

{#if isSubmitting}
  <Loading translucid />
{/if}

{#if loading}
  <Loading />
{:else if !pendingPurchases}
  <div class="retry">
    <Toast type="danger">
      <span>
        Desculpe, compra não encontrada, em caso de dúvidas entre em contato com
        o time de vendas via WhatsApp <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"
          >clicando aqui</a
        >.
      </span>
    </Toast>
  </div>
{:else}
  <Header />
  <ProgressBar />
  <div class="retry">
    <div class="retry-inner">
      <header>
        <TwoPaymentsFail />
        <p>
          Ops, houve um problema no pagamento de parte de sua<br /> mensalidade.
          Entenda:
        </p>
      </header>
      <main>
        <div class="installments container">
          <span class="installments-info">
            Parcelado em {installments} mes{`${
            installments < 1 || installments > 1 ? "es" : ""
          }`}  
          </span>
          <div class="orders">
            <div class="separator"></div>
            <div class="order">
                <div class="indicator">
                  <span>
                    <Checked />
                  </span>
                </div>
                <div class="{`card ${firstPaid?.status?.toLowerCase()}`}">
                  <span class="price">{formatCurrency(firstPaid?.amount)}</span>
                  ({products[firstPaid?.productItemType?.toLowerCase()]})
                </div>
              </div>
              <div class="order">
                <div class="indicator">
                  <span>
                    <Danger />
                  </span>
                </div>
                <div class="{`card ${firstFailed?.status?.toLowerCase()}`}">
                  <span class="price">{formatCurrency(firstFailed?.amount)}</span>
                  ({products[firstFailed?.productItemType?.toLowerCase()]})
                </div>
              </div>
          </div>
        </div>

        <p>Tente um outro cartão de crédito para concluir a compra.</p>

        <div class="wrap container">
          <div class="card-brands">
            <CardBrands colored="{true}" />
          </div>
          <form class="form-inputs" bind:this="{retryFormFields}">
            <PaymentFormInputs
              shouldShowPaymentInfo="{shouldShowPaymentInfo}"
              selectedPaymentMethod="CREDIT_CARD"
              shouldShowUseAsDefaultCard="{true}"
              useSameCard="{useSameCard}"
            />
          </form>
        </div>
        <div class="same-card container">
          <Checkbox
            name="hold-card"
            id="hold-card"
            required
            bind:checkboxField="{checkboxSameCardInput}"
            onInput="{() => {
              useSameCard = !useSameCard
            }}"
          >
            <b>Tente novamente no cartão {creditCard?.cardNumber?.substr(-7)}</b
            >
          </Checkbox>
        </div>

        <div class="actions">
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a
            href="#"
            on:click|preventDefault="{retryPending}"
            class="retry-buy container"
            ><CreditCard /><span>Finalizar pagamento</span></a
          >
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"
            class="whats container"
            ><WhatsOutline /><span>Fazer contato via Whatsapp</span></a
          >
        </div>
      </main>
      <footer class="container modal-action">
        <!-- svelte-ignore a11y-invalid-attribute -->
        <p>
          Ficou com duvidas sobre a cobrança mensal no seu cartão de crédito. <a
            href="#"
            on:click|preventDefault="{() => (shouldShowChargeModal = true)}"
            >Clique aqui.</a
          >
        </p>
      </footer>
    </div>
  </div>
  <Footer
    productPrivacyUrl="{productPrivacyUrl}"
    productTermsUrl="{productTermsUrl}"
  />
{/if}
{#if shouldShowChargeModal}
  <ChargeInfo onClose="{() => (shouldShowChargeModal = false)}" />
{/if}
{#if hasSubmitError}
  <CheckoutFail
    whatsappNumber="{VITE_WHATS_CONTACT_NUMBER}"
    onClose="{() => (hasSubmitError = false)}"
    description="Não conseguimos finalizar o seu pagamento, tente novamente."
  />
{/if}

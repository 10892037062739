import { EXTERNAL_STUDENT_QUERY } from "@graphql/student"
import { commerceClient } from "./graphql-clients"
import { StudentStore } from "@stores/student"
import { captureException } from "@integrations/sentry"

const getExternalStudent = async (id) => {
  try {
    if (!id || (id && id?.trim() === "")) return
    const externalStudentData = await commerceClient.query({
      query: EXTERNAL_STUDENT_QUERY,
      variables: {
        id: id,
      },
    })

    let { data } = externalStudentData

    if (!data || (data && !data?.externalStudent)) return

    let { externalStudent } = data

    if (externalStudent) {
      StudentStore.set(externalStudent)
    }
  } catch (error) {
    captureException(error)
  }
}

export { getExternalStudent }

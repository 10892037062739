<script>
  export let fillColor = "#389b0d"
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24px"
  height="24px"
  viewBox="0 0 20 20"
  stroke="transparent"
  fill="currentColor"
>
  <rect x="2" y="2" width="16" height="16" rx="8" fill="{fillColor}"></rect>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.8574 7.1633C14.0553 7.37268 14.0461 7.7029 13.8367 7.90086L9.05409 12.4226C8.85296 12.6128 8.53834 12.6128 8.33721 12.4226L6.1633 10.3673C5.95392 10.1693 5.94466 9.83908 6.14262 9.6297C6.34058 9.42032 6.6708 9.41106 6.88018 9.60902L8.69565 11.3255L13.1198 7.14262C13.3292 6.94466 13.6594 6.95392 13.8574 7.1633Z"
    fill="white"></path>
</svg>

import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core"
import { onError } from "@apollo/client/link/error"
import { fetch as fetchPolyfill } from "whatwg-fetch"

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // TODO: Send error to error reporting service
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const fetch = window.fetch || fetchPolyfill
const commerceGraphQLAPI = import.meta.env.VITE_COMMERCE_GRAPHQL_API
const checkoutGraphQLAPI = import.meta.env.VITE_CHECKOUT_GRAPHQL_API

const commerceLink = new HttpLink({
  uri: commerceGraphQLAPI,
  fetch,
})

const checkoutLink = new HttpLink({
  uri: checkoutGraphQLAPI,
  fetch,
})

const commerceClient = new ApolloClient({
  link: errorLink.concat(commerceLink),
  cache: new InMemoryCache(),
  defaultOptions,
})

const checkoutClient = new ApolloClient({
  link: errorLink.concat(checkoutLink),
  cache: new InMemoryCache(),
  defaultOptions,
})

export { commerceClient, checkoutClient }

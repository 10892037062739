<style lang="scss">
  @import "./thank-you.scss";
</style>

<script>
  import Header from "@components/header";
  import Congratulations from "@assets/images/congratulations.svg"
  import Footer from "@components/footer";
  import Whats from "@components/icons/whats.svelte"
  import { phoneFormatter } from "@utils/phone";
  const { VITE_WHATS_CONTACT_NUMBER } = import.meta.env
  const { VITE_DEFAULT_PRIVACY_URL, VITE_DEFAULT_TERMS_URL } = import.meta.env
</script>

<Header />
<div class="thank-you">
  <div class="congratulations">
    <img src="{Congratulations}" alt="Pagamento aprovado">
    <h2>Pagamento aprovado!</h2>
    <h1>Parabéns!<br />Sua vaga para o sucesso já está garantida!</h1>
    <p>Quer seguir treinando antes do início das aulas? Vem com a gente!<br />Mande uma mensagem para o WhatsApp {phoneFormatter(VITE_WHATS_CONTACT_NUMBER)} e conheça nossas aulas especiais acontecendo nesse mês!</p>
    <a href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"><Whats width="{46}" height="{46}"/><span>conheça nossas aulas especiais<br />acontecendo nesse mês!</span></a>
    <h3>Vai no seu flow!</h3>
  </div>
</div>
<Footer
  productPrivacyUrl="{VITE_DEFAULT_PRIVACY_URL}"
  productTermsUrl="{VITE_DEFAULT_TERMS_URL}" />

import { gql } from "@apollo/client/core"

export const FRANCHISEE_GROUP_QUERY = gql`
  query getFranchiseeGroup($id: ID!, $state: String) {
    franchiseeGroup(id: $id) {
      id
      startDate
      endDate
      name
      schedules {
        id
        dayOfWeek
        hourStart
        hourEnd
      }
      franchisee {
        id
        name
        whatsapp
      }
      product {
        id
        paymentMethods {
          id
          name
          paymentMethod
          installments
        }
        category {
          category
          defaultChargeType
          successUrl
          privacyUrl
          termsUrl
        }
        idiom
        name
        installments(groupId: $id)
        description
        price(state: $state)
        images {
          url
          resolution
        }
        items {
          id
          name
          productItemType
        }
      }
    }
  }
`
export const EXTERNAL_FRANCHISEE_QUERY = gql`
  query getExternalFranchisee($id: ID!) {
    externalFranchisee(id: $id) {
      id
    }
  }
`

export const FRANCHISEES_SEARCH_QUERY = gql`
  query franchiseesSearch($search: String, $pagination: PaginationInput!) {
    franchisees(search: $search, pagination: $pagination) {
      id
      name
    }
  }
`

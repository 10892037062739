import queryString from "query-string"

const getUrlParams = () => {
  return queryString.parse(window.location.search)
}

const getUtms = () => {
  const params = getUrlParams()
  const { utm_source, utm_medium, utm_content, utm_term, utm_campaign } = params

  return {
    utmSource: utm_source,
    utmMedium: utm_medium,
    utmContent: utm_content,
    utmCampaign: utm_campaign,
    utmTerm: utm_term,
  }
}

export { getUrlParams, getUtms }

import { gql } from "@apollo/client/core"

export const PROMO_CODE_QUERY = gql`
  query getPromoCode(
    $userId: ID
    $promotionCode: String!
    $state: String
    $franchiseeId: ID
    $productIds: [ID!]
  ) {
    simulateDiscount(
      input: {
        userId: $userId
        promotionCode: $promotionCode
        state: $state
        productIds: $productIds
        franchiseeId: $franchiseeId
      }
    ) {
      promotionCode
      price
      discount
      fullPrice
    }
  }
`

export const CHECKOUT_MUTATION = gql`
  mutation buy($input: CheckoutInput!) {
    checkout(input: $input) {
      id
      userId
      status
      price
      accountStatus
      invoice {
        pdfUrl
        line
        barcodeUrl
      }
    }
  }
`
export const RETRY_PURCHASE_MUTATION = gql`
  mutation retry($input: RetryPurchaseInput!) {
    retryPurchase(input: $input)
  }
`
export const PENDING_PURCHASE_QUERY = gql`
  query getPendingPurchase($id: ID!) {
    pendingPurchase(id: $id) {
      payments {
        id
        productItemType
        status
        amount
        installments
      }
      creditCard {
        id
        cardNumber
        brand
        expirationDate
      }
    }
  }
`

<style lang="scss">
  @import "./purchase-submit.scss";
</style>
<script>
  import CreditCard from "@components/icons/credit-card.svelte"
  import BarCode from "@components/icons/bar-code.svelte"
  import { shouldSubmitForm } from "@utils/form"
  import { onMount } from "svelte"

  export let selectedPaymentMethod
  export let formFields
  export let onSubmit = () => {}
  export let submitLabel = "";

  let isProcessing = false

  const bouncedSubmit = () => {
    if (isProcessing || !shouldSubmitForm(formFields)) return

    isProcessing = true

    onSubmit()

    setTimeout(() => {
      isProcessing = false
    }, 3500)
  }

  onMount(async () => {
    let formBindInterval
    formBindInterval = setInterval(() => {
      if (formFields) {
        formFields.addEventListener("keydown", (event) => {
          if (event.key === "Enter" || event.keyCode === 13) {
            event.preventDefault();
            return false;
          }
          clearInterval(formBindInterval);
          formBindInterval = null
        });
      }
    }, 350)
  })
</script>
<div class="submit-purchase">
  <button type='submit' class="payment-button" on:click|preventDefault="{bouncedSubmit}">
    <i class="icon">
      {#if selectedPaymentMethod === "CREDIT_CARD"}
        <CreditCard />
      {/if}
      {#if selectedPaymentMethod === "INVOICE"}
        <BarCode />
      {/if}
    </i>
    <span>{submitLabel}</span>
  </button>
</div>

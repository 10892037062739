import { tick } from "svelte"
const shouldSubmitForm = (fields) => {
  let isFormValid = true

  Array.from(fields).forEach((input) => {
    const isDisabled = input.hasAttribute("disabled")
    const isRequired = input.hasAttribute("required")
    const hasPreviusError = input.classList.contains("error")
    const hasValue =
      input.type === "checkbox"
        ? input.checked === true
        : input.value.replace(/\s/g, "") !== ""

    if (isDisabled) return

    if (isRequired && !hasValue) {
      input.classList.add("error")
      isFormValid = false
    }

    if (hasPreviusError) {
      isFormValid = false
    }
  })

  return isFormValid
}

const clearInputErrors = (input) => {
  // Means email has invalidated for the external email service validation
  // and in this case is better to maintain the CSS error class
  if (input.type === "email" && input.value !== "") return

  input.classList.remove("error")
}

const normalizeFields = (fields) => {
  Array.from(fields).forEach((input) => {
    clearInputErrors(input)
  })
}

const getInputValue = async (event) => {
  await tick()
  if (!event) return
  if (event instanceof ClipboardEvent) {
    return event.clipboardData.getData("text")
  }
  const { target: { value } } = event
  return value
}

export { shouldSubmitForm, normalizeFields, getInputValue }

<style lang="scss">
  @import "./styles/global.scss";

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
</style>

<script>
  import Routes from "./routes"
  import Gtm from "@components/gtm"
  import { onDestroy } from "svelte"
  import { VITE_RECAPTCHA_KEY } from "@integrations/google"
  import { ErrorsStore } from "@stores/errors"
  import Toast from "@components/toast"
  import CloseRounded from "@components/icons/close-rounded.svelte"

  const { VITE_WHATS_CONTACT_NUMBER, VITE_GTM_ID } = import.meta.env

  let errorCode

  const errorsSub = ErrorsStore.subscribe((error) => {
    errorCode = error
  })

  const onClose = () => ErrorsStore.set(null)

  onDestroy(() => {
    errorsSub()
  })
</script>

<Gtm gtmId="{VITE_GTM_ID}" />

<svelte:head>
  <script
    src="https://www.google.com/recaptcha/enterprise.js?render={VITE_RECAPTCHA_KEY}"
    defer></script>
</svelte:head>

<main>
  {#if (errorCode === "invalid-group" || errorCode === "invalid-product") }
    {#if errorCode === "invalid-group"}
      <Toast type="danger">
        <span>
          Desculpe, não foi possível determinar uma oferta para você, entre em
          contato com o time de vendas via WhatsApp <a
            href="https://api.whatsapp.com/send?phone={VITE_WHATS_CONTACT_NUMBER}"
            >clicando aqui</a
          >.
        </span>
      </Toast>
    {/if}
    {#if errorCode === "invalid-product"}
      <Toast type="danger">
        <span>
          Desculpe, não foi possível encontrar um produto válido para você.
        </span>
      </Toast>
    {/if}
  {:else}
    {#if errorCode === "invalid-coupon"}
      <Toast
        type="danger"
        onClose="{onClose}"
        title="Cupom inválido"
        icon="{CloseRounded}"
      >
        <span> Desculpe, esse cupom não é válido. </span>
      </Toast>
    {/if}
    <Routes />
  {/if}
</main>

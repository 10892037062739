<style lang="scss">
  @import "card-brand.scss";
</style>

<script>
  export let enabled = false

  import Elo from "@assets/images/card-brands/elo.svg"
</script>

<img src="{Elo}" alt="Elo" class="card-brands {enabled ? 'enabled' : ''}" />

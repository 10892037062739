<script>
  export let width = 170
  export let height = 90
</script>

<svg
  width="{width}"
  height="{height}"
  viewBox="0 0 170 90"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M136.101 52.5178C139.427 52.5178 142.123 49.8932 142.123 46.6557C142.123 43.4181 139.427 40.7935 136.101 40.7935C132.776 40.7935 130.08 43.4181 130.08 46.6557C130.08 49.8932 132.776 52.5178 136.101 52.5178Z"
    stroke="#FFA3C0"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M150.103 8.99939C150.103 8.99939 138.129 26.2653 155.613 32.452C173.096 38.6387 170.486 18.8083 161.362 20.7239C150.979 23.5436 156.092 41.4702 167.827 41.4702"
    stroke="#389E0D"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M42.9953 14.411C42.9953 23.3779 35.5098 30.6464 26.2753 30.6464"
    stroke="#0007B3"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M22.7827 61.0879C24.7014 61.0879 26.2569 59.5736 26.2569 57.7055C26.2569 55.8375 24.7014 54.3231 22.7827 54.3231C20.8639 54.3231 19.3085 55.8375 19.3085 57.7055C19.3085 59.5736 20.8639 61.0879 22.7827 61.0879Z"
    fill="#389E0D"></path>
  <path
    d="M120.327 14.411L129.035 25.386"
    stroke="#FF0F59"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M37.578 71.2351L26.2755 79.6903"
    stroke="#FF0F59"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M0.499878 32C0.499878 32 7.32368 32 12.1423 40.6966C16.0126 47.6801 23.4899 46.852 23.4899 46.852"
    stroke="#FFAB00"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M139.136 75.2941L154.564 79.5328"
    stroke="#000AFF"
    stroke-width="3"
    stroke-miterlimit="10"></path>
  <path
    d="M160.079 54.3231L154.464 59.7901L160.079 65.2572L165.695 59.7901L160.079 54.3231Z"
    fill="#FFAB00"></path>
  <path
    d="M76.2499 45L83.7499 52.5L98.7499 37.5M121.25 45C121.25 63.6396 106.139 78.75 87.4999 78.75C68.8603 78.75 53.7499 63.6396 53.7499 45C53.7499 26.3604 68.8603 11.25 87.4999 11.25C106.139 11.25 121.25 26.3604 121.25 45Z"
    stroke="#28A745"
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round"></path>
</svg>

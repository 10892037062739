<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24px"
  height="24px"
  viewBox="0 0 20 20"
  stroke="transparent"
  fill="currentColor"
>
  <rect x="2" y="2" width="16" height="16" rx="8" fill="#FF5630"></rect>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 10.5C7 10.2239 7.22386 10 7.5 10H12.5C12.7761 10 13 10.2239 13 10.5C13 10.7761 12.7761 11 12.5 11H7.5C7.22386 11 7 10.7761 7 10.5Z"
    fill="white"></path>
</svg>

<script>
  /* eslint-disable */
  import { onMount } from "svelte"

  export let gtmId = ""
  export let gtmDataPoints = []
  export let timeout = 0

  let gtmScript

  const initGtm = (customDataPoints = [], globalObject = window) => {
    const requiredDataPoint = {
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    }

    const getGtmScript = (gtmId) => {
      if (typeof gtmId !== "string" || !gtmId.length) return

      return `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
    }

    try {
      const dataLayer = [requiredDataPoint].concat(customDataPoints)
      globalObject["dataLayer"] = globalObject["dataLayer"]
        ? [...globalObject["dataLayer"], ...dataLayer]
        : dataLayer
    } catch (error) {
      console.error("GTM", error)
    } finally {
      return getGtmScript
    }
  }

  onMount(() => {
    if (!timeout) gtmScript = initGtm(gtmDataPoints)(gtmId)
    else
      setTimeout(() => {
        gtmScript = initGtm(gtmDataPoints)(gtmId)
      }, timeout)
  })
</script>

<svelte:head>
  {#if gtmScript}
    <script src="{gtmScript}" defer></script>
    <script>
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }
      gtag("js", new Date())
    </script>
  {/if}
</svelte:head>

<svg
  width="64"
  height="67"
  viewBox="0 0 64 67"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="32" cy="32.125" r="32" fill="white"></circle>
  <path
    d="M18.5 19.125C16.0147 19.125 14 21.1397 14 23.625V25.875H50V23.625C50 21.1397 47.9853 19.125 45.5 19.125H18.5Z"
    fill="#28A745"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M50 30.375H14V41.625C14 44.1103 16.0147 46.125 18.5 46.125H45.5C47.9853 46.125 50 44.1103 50 41.625V30.375ZM18.5 39.375C18.5 38.1324 19.5074 37.125 20.75 37.125H23C24.2426 37.125 25.25 38.1324 25.25 39.375C25.25 40.6176 24.2426 41.625 23 41.625H20.75C19.5074 41.625 18.5 40.6176 18.5 39.375ZM29.75 37.125C28.5074 37.125 27.5 38.1324 27.5 39.375C27.5 40.6176 28.5074 41.625 29.75 41.625H32C33.2426 41.625 34.25 40.6176 34.25 39.375C34.25 38.1324 33.2426 37.125 32 37.125H29.75Z"
    fill="#28A745"></path>
  <circle cx="53" cy="55.125" r="8" fill="white"></circle>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M53 63.125C57.4183 63.125 61 59.5433 61 55.125C61 50.7067 57.4183 47.125 53 47.125C48.5817 47.125 45 50.7067 45 55.125C45 59.5433 48.5817 63.125 53 63.125ZM51.7071 52.4179C51.3166 52.0274 50.6834 52.0274 50.2929 52.4179C49.9024 52.8084 49.9024 53.4416 50.2929 53.8321L51.5858 55.125L50.2929 56.4179C49.9024 56.8084 49.9024 57.4416 50.2929 57.8321C50.6834 58.2226 51.3166 58.2226 51.7071 57.8321L53 56.5392L54.2929 57.8321C54.6834 58.2226 55.3166 58.2226 55.7071 57.8321C56.0976 57.4416 56.0976 56.8084 55.7071 56.4179L54.4142 55.125L55.7071 53.8321C56.0976 53.4416 56.0976 52.8084 55.7071 52.4179C55.3166 52.0274 54.6834 52.0274 54.2929 52.4179L53 53.7108L51.7071 52.4179Z"
    fill="#DC3545"></path>
  <circle cx="14" cy="56.875" r="8" fill="white"></circle>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14 64.875C18.4183 64.875 22 61.2933 22 56.875C22 52.4567 18.4183 48.875 14 48.875C9.58172 48.875 6 52.4567 6 56.875C6 61.2933 9.58172 64.875 14 64.875ZM17.7071 55.5821C18.0976 55.1916 18.0976 54.5584 17.7071 54.1679C17.3166 53.7774 16.6834 53.7774 16.2929 54.1679L13 57.4608L11.7071 56.1679C11.3166 55.7774 10.6834 55.7774 10.2929 56.1679C9.90237 56.5584 9.90237 57.1916 10.2929 57.5821L12.2929 59.5821C12.6834 59.9726 13.3166 59.9726 13.7071 59.5821L17.7071 55.5821Z"
    fill="#28A745"></path>
</svg>

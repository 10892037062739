<style lang="scss">
  @import "./checkout.scss";
</style>

<script>
  import ContactForm from "@components/contact-form"
  import PaymentForm from "@components/payment-form"
  import PurchaseDetails from "../purchase-details"
  import { FranchiseeGroupStore } from "@stores/franchisee-group"
  import { onMount, onDestroy } from "svelte"
  import itemType from "@utils/item-type"
  import { prepareCheckoutFields, redirect } from "@utils/checkout"
  import { purchase, initCheckout } from "@services/checkout"
  import Loading from "@components/loading"
  import CheckoutFail from "@components/checkout-fail"
  import { ErrorsStore } from "@stores/errors"
  import { generateCaptchaToken } from "@integrations/google"
  import { CheckoutStore } from "@stores/checkout"
  import { ProgressBarProgressStore } from "@stores/progress-bar-progress"
  import { SectionTitleIndexesStore } from "@stores/section-title-indexes"
  import { navigate } from "svelte-routing"
  import Footer from "@components/footer"
  import FranchiseesSearch from "@components/franchisees-search"
  import { get } from "svelte/store"
  import { getUrlParams } from "@utils/url"
  import UserDetails from "@components/user-details"
  import { StudentStore } from "@stores/student"

  export let isDonation = false;
  export let showInfoForBuyer = true;
  export let personalNameLabel = "Nome"
  export let personalDocumentLabel = "CPF"
  export let shouldSupportCoupon = true;

  let totalInstallments
  let subtotal
  let productDiscount = 0
  let productPromotionCode = ""
  let groupSchedules = []
  let material
  let productDescription
  let productChargeType
  let productCategory
  let groupStartDate
  let productName
  let franchiseId
  let groupName
  let productImage
  let loading = false
  let freeCharge = false
  let checkoutFail = false
  let checkoutFailCode
  let whatsappNumber = import.meta.env.VITE_WHATS_CONTACT_NUMBER
  let selectedPaymentMethod
  let checkoutFormFields
  let isInitingCheckout = true
  let checkoutData
  let shouldShowRetryModal = false
  let hasGroup = false;
  let productTermsUrl;
  let productPrivacyUrl;
  let productSuccessUrl;
  let sectionTitleIndexes = [];
  let student

  const params = getUrlParams()
  const { hide_franchisee = "false" } = params

  const pushPurchaseEvent = (data) => {
    /*eslint-disable */
    dataLayer.push({ ecommerce: null })
    dataLayer.push(data)
    /*eslint-enable */
  }

  const setFranchiseeId = franchisee => {
    const { id } = franchisee;
    franchiseId = id;
  }

  const setFranchiseeContactNumber = whatsapp => {
    if (whatsapp) {
      whatsappNumber = whatsapp.replace(/[^0-9]/g, "")
    }
  }

  const setGroupName = group => {
    const { name } = group;
    groupName = name
  }

  const setGroupSchedules = schedules => {
    groupSchedules = schedules?.map((schedule) => {
      return {
        ...schedule,
      }
    })
  }

  const setProductMaterial = product => {
    const { items } = product;
    if (items && items?.length > 0) {
      material = items.find(
        (item) => item?.productItemType?.toLowerCase() === itemType.studentBook
      )
    }
  }

  const setProductImage = product => {
    const { images } = product;
    if (images && images?.length > 0) {
      const productImageDefault = images.find(
        (image) => image.resolution === "2x"
      )
      if (productImageDefault) {
        const { url } = productImageDefault
        productImage = url
      }
    }
  }

  const setSubTotal = product => {
    const { price } = product;
    subtotal = price
  }

  const sectionTitleSub = SectionTitleIndexesStore.subscribe((data) => {
    sectionTitleIndexes = data
  })

  const groupSub = FranchiseeGroupStore.subscribe((data) => {
    const { group } = data;

    if (!group) return

    hasGroup = true;

    const { schedules, franchisee, startDate } = group
    const { whatsapp } = franchisee

    groupStartDate = startDate

    setFranchiseeId(franchisee)
    setGroupName(group)
    setFranchiseeContactNumber(whatsapp)
    setGroupSchedules(schedules);
  })

  const errorsSub = ErrorsStore.subscribe((error) => {
    checkoutFail = false

    if (error && error?.type === "checkout-fail") {
      checkoutFail = true
      const { code } = error
      checkoutFailCode = code
    }
  })

  const checkoutStoreSub = CheckoutStore.subscribe((data) => {
    const { fields } = data;

    checkoutData = fields

    const { initing, simulateDiscount, product, chargeTypes: { selectedChargeType } } = checkoutData
    if (!Object.keys(product).length) return;

    const { installments, category: { category, privacyUrl, termsUrl, successUrl }, name, description, paymentMethods } = product;
    const { discount, promotionCode, price } = simulateDiscount
    const { selectedMethod } = paymentMethods
    const { VITE_DEFAULT_PRIVACY_URL, VITE_DEFAULT_TERMS_URL, VITE_DEFAULT_TERMS_URL_FOR_INSTITUTE } = import.meta.env

    productChargeType = selectedChargeType
    productDescription = description
    productName = name
    productCategory = category
    totalInstallments = installments
    isInitingCheckout = initing || false
    selectedPaymentMethod = selectedMethod
    productDiscount = discount || 0
    productPromotionCode = promotionCode || ""
    freeCharge = parseInt(price, 10) < 1
    productPrivacyUrl = privacyUrl ? privacyUrl : VITE_DEFAULT_PRIVACY_URL;
    productTermsUrl = termsUrl ? termsUrl : isDonation ? VITE_DEFAULT_TERMS_URL_FOR_INSTITUTE : VITE_DEFAULT_TERMS_URL;
    productSuccessUrl = successUrl;
    setSubTotal(product);
    setProductMaterial(product);
    setProductImage(product);
  })

  const checkout = async (checkoutFields) => {
    if (loading) return

    try {
      shouldShowRetryModal = false
      loading = true
      const token = await generateCaptchaToken()

      const response = await purchase(checkoutFields, token)
      const { error } = response;

      if (error) throw new Error(error)

      const { id, userId, accountStatus, price, status } = response
      const {
        product: { productId, category },
        installments,
      } = checkoutFields

      pushPurchaseEvent({
        event: "purchase",
        ecommerce: {
          currency: "BRL",
          transaction_id: id,
          value: subtotal,
          items: [
            {
              item_id: productId,
              item_name: productName,
              coupon: productPromotionCode,
              discount: Number(productDiscount.toFixed(2)),
              price: price,
              quantity: 1,
            },
          ],
        },
      })

      if (
        status === "COMPLETED" &&
        productCategory === "ADD_ON" &&
        !student &&
        selectedPaymentMethod === "CREDIT_CARD"
      ) {
        return redirect({
          accountStatus,
          userId,
          category: productCategory,
        })
      }

      if (status === "COMPLETED") return redirect({
        accountStatus,
        userId,
        category: productCategory,
        successUrl: productSuccessUrl
      })

      if (
        status === "PARTIALLY_COMPLETED" &&
        selectedPaymentMethod === "CREDIT_CARD"
      ) {
        let purchaseData = {
          installments: installments,
          productDiscount,
          subtotal,
          accountStatus,
          userId,
          purchaseId: id,
          productPrivacyUrl,
          productTermsUrl,
          productSuccessUrl,
          productCategory,
        }

        return navigate(`/retry?purchase=${btoa(JSON.stringify(purchaseData))}`)
      }

      if (selectedPaymentMethod === "INVOICE") {
        const { invoice } = response
        const invoiceData = {
          ...invoice,
          accountStatus: accountStatus,
          userId,
          installments: installments,
          productPrivacyUrl,
          productTermsUrl,
          productSuccessUrl,
          productCategory,
          installmentValue:
            (subtotal - productDiscount) / installments,
        }
        return navigate(
          `/invoice?purchase=${btoa(JSON.stringify(invoiceData))}`
        )
      }
      return redirect({
        accountStatus,
        userId,
        category: productCategory,
        successUrl: productSuccessUrl
      })
    } catch (error) {
      let messageError = ""
      if (error) {
        const { message } = error
        if (message) messageError = message
      }
      ErrorsStore.set({ type: "checkout-fail", code: messageError })
      loading = false
    }
  }

  const submit = async () => {

    const checkoutFields = prepareCheckoutFields(checkoutData)

    await checkout(checkoutFields)
  }

  const closeCheckoutFail = (e) => {
    e.preventDefault()
    ErrorsStore.set(null)
  }

  const conditionalSectionTitleIndexes = () => {
    let sectionTitleIndexes = get(SectionTitleIndexesStore);
    const franchiseeIndex = sectionTitleIndexes?.indexOf("franchisee");
    if (!isDonation && franchiseeIndex !== -1) {
      sectionTitleIndexes.splice(franchiseeIndex, 1);
    } else if (isDonation && sectionTitleIndexes?.length < 5 && franchiseeIndex === -1) {
      sectionTitleIndexes.splice(1, 0, "franchisee");
    } else if (isDonation && hide_franchisee.toString() === "true") {
      sectionTitleIndexes = sectionTitleIndexes.filter(title => title !== "franchisee")
    }

    if (student) {
      sectionTitleIndexes.splice(1, 0, "student");
    }

    SectionTitleIndexesStore.set(sectionTitleIndexes);
  }

  const studentSub = StudentStore.subscribe(async (data) => {
    if (Object.keys(data).length > 0) {
      student = data;
    }
  })

  onMount(async () => {
    ProgressBarProgressStore.set(66)
    await initCheckout()
    conditionalSectionTitleIndexes()
  })

  onDestroy(() => {
    groupSub()
    errorsSub()
    checkoutStoreSub()
    sectionTitleSub()
    studentSub()
  })
</script>

{#if loading}
  <Loading translucid />
{/if}

{#if isInitingCheckout}
  <Loading />
{:else}
  {#if checkoutFail}
    <CheckoutFail
      whatsappNumber="{whatsappNumber}"
      onClose="{closeCheckoutFail}"
      code="{checkoutFailCode}"
    />
  {/if}

  <form
    class="checkout-main"
    bind:this="{checkoutFormFields}"
    on:submit|preventDefault
    novalidate
  >
    <div class="checkout container">
      <div class="checkout-first-column">
        <ContactForm
          account="{checkoutData?.account}"
          personalNameLabel="{personalNameLabel}"
          personalDocumentLabel="{personalDocumentLabel}"
          showInfoForBuyer="{showInfoForBuyer}"
          isDonation="{isDonation}"
          titleContactIndex="{sectionTitleIndexes?.indexOf("contact") + 1}"
        />
        {#if !!student}
          <UserDetails
            user="{student}"
            showAddress="{!!student?.address}"
            title="2. Informação do aluno"
            addressLabel="Endereço de faturamento"
          />
        {/if}
        {#if isDonation && hide_franchisee.toString() !== "true"}
          <FranchiseesSearch
            titleFranchiseeIndex="{sectionTitleIndexes?.indexOf("franchisee") + 1}"
          />
        {/if}
        <PaymentForm
          freeCharge="{freeCharge}"
          selectedPaymentMethod="{selectedPaymentMethod}"
          subtotal="{subtotal}"
          productDiscount="{productDiscount}"
          installments="{totalInstallments}"
          creditCard="{checkoutData?.creditCard}"
          isDonation="{isDonation}"
          titleInstallmentsIndex="{sectionTitleIndexes?.indexOf("installments") + 1}"
          titlePaymentIndex="{sectionTitleIndexes?.indexOf("payment") + 1}"
        />
      </div>
      <div class="checkout-second-column">
        <PurchaseDetails
          hasGroup="{hasGroup}"
          installments="{totalInstallments}"
          productDiscount="{productDiscount}"
          productPromotionCode="{productPromotionCode}"
          franchiseId="{franchiseId}"
          groupSchedules="{groupSchedules}"
          material="{material}"
          productDescription="{productDescription}"
          startDate="{groupStartDate}"
          subtotal="{subtotal}"
          productName="{productName}"
          productCategory="{productCategory}"
          productImage="{productImage}"
          selectedPaymentMethod="{selectedPaymentMethod}"
          productPrivacyUrl="{productPrivacyUrl}"
          productTermsUrl="{productTermsUrl}"
          productChargeType="{productChargeType}"
          formFields="{checkoutFormFields}"
          onSubmit="{submit}"
          shouldSupportCoupon="{shouldSupportCoupon}"
          isDonation="{isDonation}"
          titleDetailsIndex="{sectionTitleIndexes?.indexOf("details") + 1}"
        />
      </div>
    </div>
  </form>
  <Footer
    productPrivacyUrl="{productPrivacyUrl}"
    productTermsUrl="{productTermsUrl}"
  />
{/if}

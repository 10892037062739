const formatAddress = (address) => {
  if (!address) return { firstLine: "", secondLine: "" }

  return {
    firstLine: `${address?.streetAddress}${
      address?.number ? ", " + address?.number : ""
    }${address?.additionalAddress ? ", " + address?.additionalAddress : ""} - ${
      address?.district
    }`,
    secondLine: `${address?.city?.name} - ${address?.city?.state}, ${address?.postalCode}`,
  }
}

export { formatAddress }

import {
  USER_QUERY,
  UPDATE_LIFE_CYCLE_MUTATION,
  VERIFY_USER_EMAIL_QUERY,
  USER_EMAIL_QUERY,
} from "@graphql/user"
import { commerceClient } from "./graphql-clients"
import { UserStore } from "@stores/user"
import { captureException } from "@integrations/sentry"

const updateUserLifeCycle = async (userId, lifecycle) => {
  try {
    if (!userId || !lifecycle) return
    await commerceClient.mutate({
      mutation: UPDATE_LIFE_CYCLE_MUTATION,
      variables: {
        userId,
        lifecycle,
      },
    })
  } catch (error) {
    captureException(error)
  }
}

const getUser = async (userId) => {
  try {
    if (!userId || (userId && userId?.trim() === "")) return
    const userData = await commerceClient.query({
      query: USER_QUERY,
      variables: {
        id: userId,
      },
    })

    let { data } = userData

    if (!data || (data && !data?.user)) return

    let { user } = data

    let { phone } = user

    if (phone && phone.slice(0, 1) === "+") {
      phone = phone.substring(3)
      user = {
        ...user,
        phone,
      }
    }
    if (user) {
      UserStore.set(user)
    }
  } catch (error) {
    captureException(error)
  }
}

const verifyUserEmail = async (email) => {
  try {
    const response = await commerceClient.query({
      query: VERIFY_USER_EMAIL_QUERY,
      variables: {
        email,
      },
    })
    const { data } = response
    if (!data || (data && !data?.verifyEmail)) return
    const { verifyEmail } = data
    return verifyEmail
  } catch (error) {
    captureException(error)
  }
}

const getUserEmail = async id => {
  try {
    const response = await commerceClient.query({
      query: USER_EMAIL_QUERY,
      variables: {
        id
      }
    });
   
    const { data } = response;
    
    if (data) {
      const { user: {email} } = data;
      return email;
    }
  } catch(error) {
    captureException(error)
  }
}

export { getUser, updateUserLifeCycle, verifyUserEmail, getUserEmail }

import { gql } from "@apollo/client/core"

export const ORDER_QUERY = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      franchisee {
        name
      }
      status
      discount
      price
      chargeType
      discount
      fullPrice
      installments
      firstPaymentDate
      items {
        discount
        fullPrice
        price
        productItem {
          name
          productItemType
        }
      }
      customer {
        id
        accountStatus
        cpf
        email
        name
        phone
        address {
          postalCode
          streetAddress
          number
          additionalAddress
          district
          city {
            name
            state
          }
        }
      }
      paymentMethod {
        paymentMethod
      }
      student {
        id
        cpf
        email
        name
        phone
        accountStatus
        address {
          postalCode
          streetAddress
          number
          additionalAddress
          district
          city {
            name
            state
          }
        }
      }
      product {
        description
        name
        idiom
        price
        category {
          category
          name
          privacyUrl
          successUrl
          termsUrl
        }
        images {
          resolution
          url
        }
        items {
          productItemType
        }
      }
      group {
        startDate
        endDate
        name
        schedules {
          id
          dayOfWeek
          hourStart
          hourEnd
        }
      }
    }
  }
`

export const CHECKOUT_ORDER_MUTATION = gql`
  mutation checkoutOrder($input: OrderInput!) {
    checkoutOrder(input: $input) {
      id
      status
      price
      userId
      accountStatus
      invoice {
        pdfUrl
        line
        barcodeUrl
      }
    }
  }
`

<style lang="scss">
  @import "card-brand.scss";
</style>

<script>
  export let enabled = false

  import Visa from "@assets/images/card-brands/visa.svg"
</script>

<img src="{Visa}" alt="Visa" class="card-brands {enabled ? 'enabled' : ''}" />

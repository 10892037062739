import { gql } from "@apollo/client/core"

export const SERVICE_QUERY = gql`
  query getService($id: ID!) {
    service(id: $id) {
      id
      discount
      dueDate
      startAt
      endAt
      fee
      fullPrice
      price
      servicePrice
      status
      taxes
      franchisee {
        id
        name
      }
      franchisees {
        franchisee {
          id
          name
        }
        fullPrice
        fee
        price
        servicePrice
      }
      invoice {
        pdfUrl
        line
        barcodeUrl
        status
      }
    }
  }
`

import { commerceClient } from "./graphql-clients"
import { ORDER_QUERY } from "@graphql/order"
import { captureException } from "@integrations/sentry"
import { checkoutClient } from "./graphql-clients"
import { CHECKOUT_ORDER_MUTATION } from "@graphql/order"
import { trimWhiteSpaces } from "@utils/checkout"

const getCustomerOrder = async (id) => {
  try {
    const response = await commerceClient.query({
      query: ORDER_QUERY,
      variables: { id },
    })
    const {
      data: { order },
    } = response
    if (!order) throw response
    return order
  } catch (error) {
    captureException(error)
  }
}

const customerOrder = async (input, token) => {
  try {
    const trimmedInput = trimWhiteSpaces(input)

    const response = await checkoutClient.mutate({
      mutation: CHECKOUT_ORDER_MUTATION,
      variables: { input: trimmedInput },
      context: {
        headers: { "X-Captcha": token, "x-captcha-version": "3" },
      },
    })
    const {
      data: { checkoutOrder },
    } = response
    if (!checkoutOrder) throw response
    return checkoutOrder
  } catch (error) {
    captureException(error)
  }
}

export { getCustomerOrder, customerOrder }

<style lang="scss">
  @import "card-brand.scss";
</style>

<script>
  export let enabled = false

  import Hiper from "@assets/images/card-brands/hiper.svg"
</script>

<img src="{Hiper}" alt="Hiper" class="card-brands {enabled ? 'enabled' : ''}" />

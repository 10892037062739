<style lang="scss">
  @import "./payment-methods.scss";
</style>

<script>
  import { get } from "svelte/store"
  import { CheckoutStore } from "@stores/checkout"
  import { setCheckoutFields, getInstallments } from "@utils/checkout"

  import CardBrands from "@components/card-brands"
  import Invoice from "@assets/images/invoice.png"
  export let selectedPaymentMethod
  export let card
  export let shouldHideNotSelectedMethod = false

  const onChange = (e) => {
    const { value } = e.target
    const checkoutStore = get(CheckoutStore)

    const {
      fields: { product },
    } = checkoutStore

    const { paymentMethods, defaultInstallments } = product
    const { available } = paymentMethods

    const productInstallments = getInstallments({
      paymentMethods: available,
      defaultInstallments,
      currentPaymentMethod: value,
    })

    setCheckoutFields({
      product: {
        ...product,
        installments: {
          ...productInstallments,
        },
        paymentMethods: {
          ...paymentMethods,
          selectedMethod: value,
        },
      },
    })
  }
</script>

<div class="payment-methods">
  {#if (selectedPaymentMethod === "CREDIT_CARD" && shouldHideNotSelectedMethod) || !shouldHideNotSelectedMethod}
    <label class="payment-method-by-card">
      <input
        checked="{selectedPaymentMethod === 'CREDIT_CARD'}"
        disabled="{shouldHideNotSelectedMethod}"
        on:change="{onChange}"
        type="radio"
        value="CREDIT_CARD"
        name="payment-options" />
      <span class="payment-method"></span>
      <span>Cartão de crédito</span>
      <CardBrands card="{card}" />
    </label>
    <slot />
  {/if}

  {#if (selectedPaymentMethod === "INVOICE" && shouldHideNotSelectedMethod) || !shouldHideNotSelectedMethod}
    <label class="payment-method-by-invoice">
      <input
        checked="{selectedPaymentMethod === 'INVOICE'}"
        disabled="{shouldHideNotSelectedMethod}"
        on:change="{onChange}"
        type="radio"
        value="INVOICE"
        name="payment-options" />
      <span class="payment-method"></span>
      <span>Boleto</span>
      <img src="{Invoice}" alt="Boleto parcelado" width="59" height="24" />
    </label>
  {/if}
</div>

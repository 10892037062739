<style lang="scss">
  @import "./payment-form-inputs.scss";
</style>

<script>
  import { onMount, tick } from "svelte"
  import IMask from "imask"
  import replaceSpecialCharacters from "replace-special-characters"
  import creditCardType from "credit-card-type"
  import { setCheckoutFields } from "@utils/checkout"
  import { getInputValue } from "@utils/form"
  import Checkbox from "@components/checkbox/checkbox.svelte"
  export let shouldShowPaymentInfo = true
  export let freeCharge = false
  export let selectedPaymentMethod
  export let shouldShowUseAsDefaultCard = false
  export let useSameCard = false

  export let creditCard = {
    holder: "",
    cardNumber: "",
    securityCode: "",
    expirationDate: "",
  }

  export let setCardBrand = () => {}

  let cardHolderInputField
  let cardNumberInputField
  let cardExpirationInputField
  let cardCVVInputField
  let shouldDisabledPaymentForm = false
  let checkboxHoldCardInput = {}
  let useAsDefault = false

  const validCardBrands = [
    "visa",
    "mastercard",
    "american-express",
    "elo",
    "hiper",
    "hipercard",
  ]

  const cardNumberMaskOption = {
    mask: "0000 0000 0000 0000",
  }

  const cardCVVMaskOptions = {
    mask: "0000",
  }

  const cardExpirationOptions = {
    mask: "00/0000",
  }

  const normalizeCardHolderName = async (holder) => {
    if (!holder) return
    holder = replaceSpecialCharacters(
      holder.replace(/[0-9]/g, "")
    )
    return holder
  }

  const validateCardExpirationDate = () => {
    const { value } = cardExpirationInputField
    if (!value) return
    const today = new Date()
    const expirationDate = new Date()
    const inputDate = value.split("/")
    const month = inputDate[0]
    let year = inputDate[1]

    if (!month || !year || parseInt(month, 10) > 12) {
      cardExpirationInputField.classList.add("error")
      return
    }

    if (year?.length === 2) {
      year = `20${year}`
    }

    expirationDate.setFullYear(year, month, 0)

    if (expirationDate < today) {
      cardExpirationInputField.classList.add("error")
    }
  }

  const validateCVV = () => {
    const { value } = cardCVVInputField
    if (value?.length < 3) {
      cardCVVInputField.classList.add("error")
    }
  }

  const getCardBrand = (creditCardNumber) => {
    if (!creditCardNumber) return
    const brand = creditCardType(creditCardNumber?.replaceAll(" ", ""))
    if (brand && brand.length) {
      const type = brand[0].type
      const matchBrand = validCardBrands.find((brand) => brand === type)
      if (matchBrand) {
        setCardBrand(type)
      }
    }
  }

  const setCardHolder = async (event) => {
    let holder = await getInputValue(event)
    holder = await normalizeCardHolderName(holder)
    setCheckoutFields({
      creditCard: { holder }
    })
  }

  const setCardNumber = async (event) => {
    const cardNumber = await getInputValue(event)
    getCardBrand(cardNumber)
    setCheckoutFields({
      creditCard: { cardNumber },
    })
  }

  const setCardExpiration = async (event) => {
    const expirationDate = await getInputValue(event)
    setCheckoutFields({
      creditCard: { expirationDate },
    })
  }

  const setCardCVV = async (event) => {
    const cvv = await getInputValue(event)
    setCheckoutFields({
      creditCard: { cvv },
    })
  }

  $: {
    shouldDisabledPaymentForm =
      freeCharge || selectedPaymentMethod === "INVOICE" || useSameCard === true
  }

  onMount(() => {
    IMask(cardNumberInputField, cardNumberMaskOption)
    IMask(cardCVVInputField, cardCVVMaskOptions)
    IMask(cardExpirationInputField, cardExpirationOptions)
  })
</script>

<div class="payment-form">
  <div>
    <input
      class="sentry-mask"
      type="text"
      placeholder="Nome impresso no cartão"
      bind:this="{cardHolderInputField}"
      required
      on:focus="{cardHolderInputField?.classList?.remove('error')}"
      disabled="{shouldDisabledPaymentForm}"
      on:keyup="{setCardHolder}"
      on:paste="{setCardHolder}"
    />
    <input
      class="sentry-mask"
      type="text"
      required
      placeholder="Número do cartão"
      bind:this="{cardNumberInputField}"
      on:focus="{cardNumberInputField?.classList?.remove('error')}"
      on:keyup="{setCardNumber}"
      on:paste="{setCardNumber}"
      disabled="{shouldDisabledPaymentForm}"
    />
  </div>
  <div class="payment-form-cvv">
    <span style="position: relative; display: inline-block">
      <input
        class="sentry-mask"
        type="text"
        name="expiration-date"
        placeholder="Data de validade MM/AA"
        required
        bind:this="{cardExpirationInputField}"
        on:focus="{cardExpirationInputField?.classList?.remove('error')}"
        disabled="{shouldDisabledPaymentForm}"
        on:blur="{validateCardExpirationDate}"
        on:keyup="{setCardExpiration}"
        on:paste="{setCardExpiration}"
      />
      {#if !shouldDisabledPaymentForm}
        <small class="error">A data de expiração é inválida.</small>
      {/if}
    </span>
    <input
      class="sentry-mask"
      style="flex-grow: 0;flex-shrink: 1"
      type="text"
      placeholder="CVV"
      required
      bind:this="{cardCVVInputField}"
      on:focus="{cardCVVInputField?.classList?.remove('error')}"
      on:blur={validateCVV}
      disabled="{shouldDisabledPaymentForm}"
      on:paste="{setCardCVV}"
      on:keyup="{setCardCVV}"
    />
  </div>
  {#if shouldShowUseAsDefaultCard}
    <div class="hold-card">
      <Checkbox
        name="hold-card"
        id="hold-card"
        disabled="{shouldDisabledPaymentForm}"
        bind:checkboxField="{checkboxHoldCardInput}"
        onInput="{() => {
          useAsDefault = !useAsDefault
          shouldShowPaymentInfo = !shouldShowPaymentInfo
          setCheckoutFields({
            creditCard: { useAsDefault },
          })
        }}"
      >
        <span>Manter cartão para as próximas mensalidades</span>
      </Checkbox>
    </div>
  {/if}
  {#if shouldShowPaymentInfo}
    <small class="payment-info"
      >Suas informações de pagamento serão utilizadas de forma segura e
      criptografada para pagamento das mesalidades.</small
    >
  {/if}
</div>

import itemType from "@utils/item-type"

const getStudentMaterial = (productItems) => {
  if (!productItems) return
  return productItems.find(
    (item) => item?.productItemType?.toLowerCase() === itemType.studentBook
  )
}

export { getStudentMaterial }

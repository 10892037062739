<style lang="scss">
  @import "./checkbox.scss";
</style>

<script>
  import Checked from "../icons/checked.svelte"
  export let onInput = () => {}
  export let checkboxField
  export let name = "checkbox"
  export let checked = false
  export let disabled = false
  export let required = false
</script>

<label class="checkbox">
  <input
    type="checkbox"
    name="{name}"
    checked="{checked}"
    disabled="{disabled}"
    required="{required}"
    {...$$props}
    bind:this="{checkboxField}"
    on:input="{onInput}"
  />
  <span>
    <i><Checked /></i>
  </span>
  <slot />
</label>

import gql from "graphql-tag";

export const PRODUCT_QUERY = gql`
  query getProduct(
    $id: ID!
    $state: String
    $franchiseeId: ID
  ) {
    product(id: $id) {
      id
      paymentMethods {
        id
        name
        paymentMethod
        installments
      }
      name
      availableForSale(franchiseeId: $franchiseeId)
      category {
        category
        defaultChargeType
        successUrl
        privacyUrl
        termsUrl
      }
      status
      price(state: $state)
      description
      installments
      images {
        url
        resolution
      }
    }
  }
`

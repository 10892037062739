<style lang="scss">
  @import "card-brand.scss";
</style>

<script>
  export let enabled = false

  import MasterCard from "@assets/images/card-brands/mastercard.svg"
</script>

<img
  src="{MasterCard}"
  alt="Master Card"
  class="card-brands {enabled ? 'enabled' : ''}"
/>

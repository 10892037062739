<style lang="scss">
  @import "./invoice.scss";
</style>

<script>
  import { onMount } from "svelte"
  import { ProgressBarProgressStore } from "@stores/progress-bar-progress"
  import Congratulations from "@components/icons/congratulation.svelte"
  import { redirect, getBase64PurchaseData } from "@utils/checkout"
  import Loading from "@components/loading"
  import { formatCurrency } from "@utils/currency"
  import { getUserEmail } from "@services/user"
  import Header from "@components/header"
  import ProgressBar from "@components/progress-bar"
  import Footer from "@components/footer"
  import InvoiceDetails from "@components/invoice-details"

  let loading = true
  let email;
  let enableRedirect = true

  let invoice = {}

  const performRedirect = () => {
    const { accountStatus, userId, productCategory, productSuccessUrl} = invoice;
    redirect({
      accountStatus,
      userId,
      category: productCategory,
      successUrl: productSuccessUrl,
      pending: true
    })
  }

  onMount(async () => {
    ProgressBarProgressStore.set(100)
    invoice = getBase64PurchaseData()

    const {
      userId,
    } = invoice
    if (userId) {
      email = await getUserEmail(userId);
    }
    ({ enableRedirect } = invoice || true)
    loading = false;
  })
</script>

{#if loading}
  <Loading />
{/if}
<Header />
<ProgressBar />
<div class="invoice">
  <header>
    <Congratulations width="170" height="70" />
    <h1>Processo concluído</h1>
    <h2>
      Seu pedido foi feito e será processado logo após o pagamento do boleto.
    </h2>
    {#if invoice?.productCategory !== "CONVENTION" && email}
      <h3>
        <div>
          Após a confirmação do pagamento você receberá um link no seu email
        </div>
        <span>{email}</span>
        <div>para finalizar o seu cadastro.</div>
      </h3>
    {/if}
  </header>
  <main>
    <div class="order-value">
      <div>Valor do pedido:</div>
      <div>
        Parcela: <span>01</span> de
        <span>
          {invoice?.installments < 10
            ? `0${invoice?.installments}`
            : invoice?.installments}
        </span>
        - Valor:
        <span>{formatCurrency(invoice?.installmentValue)}</span>
      </div>
    </div>
    <InvoiceDetails invoice="{invoice}" />
  </main>
  <footer>
    <!-- svelte-ignore a11y-invalid-attribute -->
    {#if enableRedirect}
      <div class="continue">
        <a href="#" on:click|preventDefault="{performRedirect}">Continuar</a>
      </div>
    {/if}
    <div class="charge-info">
      <p>
        <b>Informações importantes sobre o pagamento do Boleto</b><br />
        - Você pode não conseguir realizar o pagamento imediatamente. Neste caso,
        tente novamente após 30 segundos.<br />
        - Se o Boleto não for pago até a data de vencimento, seu pedido será cancelado.
        O Boleto estará disponível em Seus pedidos até esta data.<br />
        - Evite pagar o boleto no dia do vencimento caso haja algum feriado local
        em sua cidade, assim como após o expediente bancário em dias úteis. Alguns
        lugares recebem o pagamento, mas só o repassam para o CNA no dia útil
        seguinte, quando o boleto pode já ter vencido. Atente-se às regras de onde
        você fará o pagamento para evitar que seu pedido seja cancelado.<br />
      </p>
    </div>
  </footer>
</div>
<Footer
  productPrivacyUrl="{invoice?.productPrivacyUrl}"
  productTermsUrl="{invoice?.productTermsUrl}"
/>

<style lang="scss">
  @import "./progress-bar.scss";
</style>

<script>
  import { onDestroy } from "svelte"
  import { ProgressBarProgressStore } from "@stores/progress-bar-progress"
  let progress

  const progressBarProgressSub = ProgressBarProgressStore.subscribe(
    (data) => (progress = data)
  )

  onDestroy(() => {
    progressBarProgressSub()
  })
</script>

<div class="progress-bar">
  <div class="outer-progress-bar">
    <div class="inner-progress-bar" style="{`width:${progress}%`}"></div>
  </div>
  <div class="labels">
    <span class="completed">Localização</span>
    <span class="completed">Turma</span>
    <span class="completed">Pagamento</span>
    <span class="{`${progress === 100 ? 'completed' : ''}`}">Sucesso</span>
  </div>
</div>

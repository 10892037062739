<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.3618 3.77967C14.721 2.13696 12.5389 1.23191 10.2141 1.23096C5.42391 1.23096 1.52519 5.12936 1.52332 9.92102C1.52265 11.4528 1.92284 12.9479 2.6834 14.2658L1.45044 18.7692L6.05753 17.5607C7.3269 18.2531 8.7561 18.618 10.2106 18.6185H10.2142C10.2144 18.6185 10.214 18.6185 10.2142 18.6185C15.0039 18.6185 18.9029 14.7197 18.9049 9.92797C18.9058 7.60578 18.0026 5.4223 16.3618 3.77967ZM10.2141 17.1508H10.2112C8.9151 17.1503 7.6438 16.802 6.53474 16.1439L6.271 15.9874L3.53708 16.7045L4.26684 14.039L4.09507 13.7657C3.37202 12.6156 2.99014 11.2864 2.9907 9.92152C2.99225 5.93888 6.23272 2.69876 10.2171 2.69876C12.1464 2.69936 13.9601 3.45172 15.3239 4.81708C16.6876 6.18243 17.4383 7.9973 17.4375 9.92737C17.4359 13.9103 14.1955 17.1508 10.2141 17.1508Z"
    fill="#6B7280"></path>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M14.1765 11.7409C13.9593 11.6322 12.8917 11.1069 12.6927 11.0344C12.4936 10.962 12.3488 10.9257 12.2041 11.1431C12.0594 11.3605 11.6432 11.8496 11.5165 11.9946C11.3899 12.1394 11.2631 12.1577 11.046 12.0489C10.8289 11.9402 10.1292 11.7109 9.2998 10.9711C8.65421 10.3953 8.21843 9.68419 8.09171 9.46674C7.96506 9.2494 8.07823 9.13189 8.18696 9.02358C8.28464 8.92625 8.40413 8.76997 8.51271 8.64315C8.62126 8.5164 8.65746 8.42573 8.72985 8.28092C8.80224 8.13596 8.76608 8.00914 8.71175 7.90048C8.65746 7.79179 8.22319 6.72295 8.04225 6.28808C7.86593 5.86471 7.68693 5.92207 7.55362 5.91533C7.42715 5.90902 7.28219 5.90771 7.13745 5.90771C6.9927 5.90771 6.7574 5.96208 6.5584 6.17942C6.35936 6.39684 5.79834 6.92223 5.79834 7.99097C5.79834 9.05985 6.57646 10.0924 6.68504 10.2373C6.79359 10.3823 8.21627 12.5756 10.3946 13.5162C10.9127 13.74 11.3172 13.8736 11.6325 13.9736C12.1527 14.1389 12.6261 14.1156 13.0003 14.0597C13.4175 13.9973 14.2851 13.5344 14.466 13.0272C14.647 12.5199 14.647 12.0851 14.5927 11.9945C14.5384 11.904 14.3936 11.8496 14.1765 11.7409Z"
    fill="#6B7280"></path>
</svg>

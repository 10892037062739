<script>
  export let productPrivacyUrl;
  export let productTermsUrl;
</script>
<style lang="scss">
  @import "./footer.scss";
</style>

<footer class="footer">
  <div class="inner-footer">
    <a href="{productPrivacyUrl}" target="_blank">
      Política de Privacidade
    </a>
    <span class="separator">-</span>
    <a href="{productTermsUrl}" target="_blank"> Termos e Condições </a>
  </div>
</footer>

<style lang="scss">
  @import "./modal.scss";
</style>

<script>
  import { onMount, onDestroy } from "svelte"
  import { fade } from "svelte/transition"
  import Header from "@components/header"
  import ProgressBar from "@components/progress-bar"
  import Footer from "@components/footer"
  export let dismissable = true
  export let lightbox = true
  export let onClose = () => {}

  onMount(() => {
    document.documentElement.classList.add("no-scroll")
  })

  onDestroy(() => {
    document.documentElement.classList.remove("no-scroll")
  })
</script>

<div
  class="{`modal ${lightbox === true ? 'lightbox' : ''}`}"
  transition:fade="{{ duration: 150 }}"
  on:click|preventDefault="{() => {
    if (!dismissable) return
    onClose()
  }}"
>
  {#if !lightbox}
    <header class="modal-header">
      <Header />
      <ProgressBar />
    </header>
  {/if}
  <div
    class="modal-inner"
    transition:fade="{{ duration: 150 }}"
    on:click|stopPropagation
  >
    <slot />
  </div>
  {#if !lightbox}
    <footer class="modal-footer">
      <Footer />
    </footer>
  {/if}
</div>

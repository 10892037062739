<style lang="scss">
  @import "./tooltip.scss";
</style>

<script>
  import InformationCircle from "@assets/images/icons/information-circle.svg"
</script>

<button
  class="btn-information"
  on:click|preventDefault
>
  <img src="{InformationCircle}" alt="Information" />
  <div class="tooltip">
    <slot />
  </div>
</button>

<style lang="scss">
  @import "./installments.scss";
</style>

<script>
  export let installments = {}
  export let subtotal
  export let productDiscount = 0
  export let freeCharge = false
  import { formatCurrency } from "@utils/currency"
  import { CheckoutStore } from "@stores/checkout"
  import { setCheckoutFields } from "@utils/checkout"
  import { get } from "svelte/store"
  export let shouldShowInstallments = false
  export let isDonation = false

  let installmentsValues = []
  
  $: {
    const installmentsArray = []
    
    for (let i = 1; i <= installments?.total; i++) {
      installmentsArray.push({ value: i })
    }
    
    installmentsValues = installmentsArray
  }

  const onClick = (selected) => {
    const checkoutStore = get(CheckoutStore)
    const {
      fields: { product },
    } = checkoutStore;
    
    const { installments: { total } } = product
    
    setCheckoutFields({
      product: {
        ...product,
        installments: {
          total,
          selectedInstallment: selected,
        }
      }
    })
  }
</script>
{#if shouldShowInstallments}
  <div class="installments {`${freeCharge ? 'disabled' : ''}`}">
    {#each installmentsValues as installment}
        <button
          disabled="{freeCharge}"
          class="installment-selector {`${
            installments?.selectedInstallment === installment?.value
              ? 'selected'
              : ''
          }`}"
          on:click|preventDefault="{() => onClick(installment?.value)}"
          value="{installment?.value}">
          <span>
            {installment?.value < 10
              ? `0${installment?.value}`
              : installment?.value}
          </span>
        </button>
      {/each}
  </div>
{/if}
{#if !freeCharge}
  <div class="selected-installment">
    <span class="selected-installment-value"
      >{formatCurrency(
        (subtotal - productDiscount) / installments?.selectedInstallment
      )}
      <span>
        {`${installments?.selectedInstallment < 2 ? "à vista" : "/mês"}`}
      </span>
    </span>
    <br />
    <span>
      {`${
          installments?.selectedInstallment < 2
            ? (isDonation ? "" : "Tudo incluso")
            : (isDonation ? "Sem juros" : "Tudo incluso e sem juros")
        }`}
    </span>
  </div>
{/if}

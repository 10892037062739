import { gql } from "@apollo/client/core"

export const EXTERNAL_STUDENT_QUERY = gql`
  query getExternalStudent($id: ID!) {
    externalStudent(id: $id) {
      id
      name
      email
      cpf
      externalId
    }
  }
`
